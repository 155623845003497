<template>
  <Panel header="快捷挪车，一点不误">
    <h5>{{ car_note }}</h5>
    <div style="max-width: 24em;margin: auto;" v-if="new_car">
      <div style="text-align: center;padding: 1em;">
        贵宾编号：{{ this.carid }}
      </div>
      <div class="p-inputgroup flex-1 ">
        <InputText placeholder="车主真实姓名" :disabled="false" v-model="carinfo.cName" />
      </div>
      <div class="place1em"></div>
      <div class="p-inputgroup flex-1 ">
        <InputText placeholder="挪车密码，用于以后修改信息" :disabled="false" v-model="carinfo.cPassWord" type="password" />
      </div>
      <div class="place1em"></div>
      <div class="p-inputgroup flex-1 ">
        <InputText placeholder="车主电话" :disabled="false" v-model="carinfo.cPhone" />
        <Button style="margin: 0.1em;" label="获取验证码" @click="this.$router.replace({ name: 'admin' })" />
      </div>
      <div class="place1em"></div>
      <div class="p-inputgroup flex-1 ">
        <InputText placeholder="验证码" :disabled="false" v-model="carinfo.cProveCode" />
      </div>
      <div class="place1em"></div>
      <div class="p-inputgroup flex-1 ">
        <InputText placeholder="车牌号" :disabled="false" v-model="carinfo.cNumber" />
      </div>
      <div class="place1em"></div>
      挪车码信息一旦保存后，后续不可轻易更改哦，请务必妥善填写车辆信息
      <div class="place1em"></div>
      <Button style="margin: 0.1em;" label="保存挪车信息" @click="save_carinfo" />
    </div>
    <div v-else>
      <div class="place1em"></div>
      <h3>{{ carinfo.cNumber }}</h3>
      <div class="place1em"></div>
      <qrcode-vue :value="'/app/carmove/' + this.carid"
        style="width: 10em;height: 10em; border-radius: 5px 5px;"></qrcode-vue>
      <div class="place1em"></div>
      <Button style="margin: 0.1em;" label="呼叫车主挪车" @click="car_note = '已经为您通知车主，等待期间，可以看看创意广告哦'" />
      <Button style="margin: 0.1em;" label="申请挪车码" @click="get_newcode" />
    </div>
    <div class="place1em"></div>
    <div>

    </div>
    <div>

    </div>

  </Panel>
  <div class="place1em"></div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import QrcodeVue from 'qrcode.vue'
import axios from 'axios';
import { ref } from "vue";


export default {

  components: {
    InputText, Button, Panel, QrcodeVue
  },
  props: ['carid'],
  data() {
    return {
      new_car: false, carinfo: [], car_note: '免通话，扫码挪车，宅男最爱...'
    }
  },
  mounted() {
    this.show_car();
    document.title = '[挪车码]-[碳硅锗兮]';
    document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
    document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
  },
  methods: {
    set_nowgroup: function (param) {
      alert(this.key);
      this.now_group = param;
      alert(param);

    },
    get_newcode: function () {
      this.$untils.$getapi('api/free/getnewcar/0').then(res => {
        console.log(res.data.data[0].carid)
        if (res.data.data && res.data.data.length > 0 && res.data.data[0].carid.length > 0) {
          console.log(res.data.data[0].carid)
          this.$router.replace({ path: '/app/carmove/' + res.data.data[0].carid })
          this.car_note = "请妥善保管您的二维码，再次扫码即可注册。"
          this.carinfo.cNumber = '京AXXXXXX'
        }
      })
        .catch(function (ea) {
          alert("加入失败" + ea)
        })
    },
    join_group: function () {
      if (this.uGroupName != null && this.uGroupName.length >= 5) {
        this.$untils.$getapi('api/FireGroups/' + this.uGroupName).then(res => {
          if (res.data.uGroupName == this.uGroupName) {
            this.$untils.$postapi('api/FireUserGroups/', {
              "id": 0,
              "uAccount": this.uAccount,
              "uGroupName": res.data.uGroupName,
              "uGroupId": res.data.uGroupId
            }).then(res => {
              alert(res.data);
              this.get_memgroup();
            })
          }
        })
          .catch(function (ea) {
            alert("加入失败" + ea)
          })
      }
      else
        alert("小组名字不得少于5个字");
    },

    create_group: function () {
      if (this.uGroupName != null && this.uGroupName.length >= 5) {
        this.$untils.$postapi("api/FireGroups", {
          uCreator: this.uAccount,
          uGroupName: this.uGroupName
        }).then(res => {
          this.get_mygroup();
          alert(res.data);
        })
      }
      else
        alert("小组名字不得少于5个字");
    },
    get_mygroup: function () {
      this.$untils.$getapi("api/FireGroups").then(res => {
        this.myGroups = ref(res.data);
      })
    },
    get_memgroup: function () {
      this.$untils.$getapi("api/FireUserGroups").then(res => {
        this.myMemGroups = ref(res.data);
      })
    },
    save_carinfo: function () {
      axios.post(this.$untils.myapiurl + 'api/FireCarMoves', {
        cCarID: this.carid,
        cName: this.carinfo.cName,
        cPassWord: this.carinfo.cPassWord,
        cPhone: this.carinfo.cPhone,
        cProveCode: this.carinfo.cProveCode,
        cNumber: this.carinfo.cNumber
      }).then((res) => {
        if (res.data == "注册成功") {
          alert("注册成功,已为您开通扫码挪车功能")
          this.new_car = false;
        }
        else {
          alert("该二维码已经被注册，请联系管理人员更换二维码")
        }
      })
    },
    show_car: function () {
      let that = this;
      axios.get(this.$untils.myapiurl + 'api/FireCarMoves/' + this.carid)
        .then(response => {
          if (response.data.cNumber.length > 0) {
            that.new_car = false;
            that.carinfo = response.data;
          }
          else {
            that.new_car = true;
          }
        })
        .catch(function (error) {
          console.log(error);
          that.new_car = true;
        })
        .then(function () {
          // 总是会执行
        });
    },
    select_member: function (hisname) {
      this.select_account = hisname;
      alert("已为您展现成员" + hisname + "的考核信息")
    },
  }
}

</script>
<style></style>

