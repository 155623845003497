<template>
    
    <div v-if="!isEdit">
        <div style="height: 1.5em;"></div>
        <div>
            <SearchBlock :sname="'经验'" @onsearch="filters" :flabel="filter_arr"></SearchBlock>
        </div>
        <div class="padding" style="min-height: 1500px;">
            <div v-for="{ dName, dDescription, dStyle1, dStyle2, dId } in blogs" :key="dId" class="div_100">
                <div style="padding: 0.5em;border-top: 1px solid rgb(238, 232, 247);text-align: left;">
                    <div class="div_33_100 lhide"><i class="pi pi-globe"></i> {{ dName }}</div>
                    <div class="div_33_100 lhide"><i class="pi pi-bookmark"></i> {{ dStyle1 }}</div>
                    <div class="div_33_100 lhide"><i class="pi pi-bookmark"></i> {{ dStyle2 }}</div>
                    <div class="div_100" style="height: 3em; overflow: hidden;">
                        <i class="pi pi-home"></i> {{ dDescription }}
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="clear"></div>

        </div>
    </div>
    <div v-else>
        <ChecklistEdit @ondatachange="listshow" :blog_id="projectname"></ChecklistEdit>
    </div>
</template>
    
<script setup>
import ChecklistEdit from '@/components/ChecklistEdit.vue';
import useBlog from '@/services/useBlog';
import useApi from "@/services/useApi";
import { ref, onMounted, defineProps,onBeforeMount,defineEmits } from "vue";
import { useStore } from "vuex";
import SearchBlock from "@/components/SearchBlock.vue";
import useSlade from '@/services/useSlade';

const props = defineProps({
    filter: String,
    project_id: String
})
let isEdit = ref(false)
let projectname = ref('')
const store = useStore();
let authed = ref(store.state.authed);
let isadmin = ref(false);
let blogs = ref([])
let local_blogs = ref([])

const filters = (name, type) => {
    blogs.value = useApi.search_item(name, type, local_blogs.value)
}
const filter_arr = [{ name: '名称', type: 'dName' }, { name: '描述', type: 'dDescription' }]
const listshow = async () => {
    if (store.state.authed)
        blogs.value = await useSlade.get_clists()
    console.log(blogs.value)
    local_blogs.value = blogs.value

}

const get_role = async () => {
    let role_data = await useBlog.get_role();
    isadmin.value = (role_data == 'SysAdmin')
}

const switch_edit = () => {
    if (isEdit.value) {
        isEdit.value = false;
        projectname = '';
        window.location.href = '/checklist/show'
    }
    else {
        isEdit.value = true;
    }
}

const my_bts = [
    {
      label: '编辑CheckList',
      icon: 'pi pi-file-edit',
      command: () => {
        switch_edit()
      }
    }
  ]
const emits = defineEmits(['add-button','remove-button']);
onBeforeMount(() => {
  emits('remove-button',my_bts);
});

onMounted(() => {
    listshow();
    if (authed.value){
        emits('add-button',my_bts);
        get_role()
    }
        
    if (props.filter && props.filter.length > 0) {
        filters()
    }
    if (props.project_id) {
        projectname.value = props.project_id
        isEdit.value = true;
    }
    document.title = '[设计Checklist]-[碳硅锗兮]';
    document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
    document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
})
</script>
    