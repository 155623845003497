import api from '@/services/useApi';
import useAlert from './useAlert';
const alert = useAlert.salert

const updateapi = api.updateapi;
const getapi = api.getapi;
const postapi = api.postapi;



function join_group(uGroupName, uAccount, uCompanyName) {
  if (uGroupName != null && uGroupName.length >= 5) {
    getapi('api/FireGroups/' + uGroupName).then(res => {
      if (res.data.uGroupName == uGroupName) {
        if (uCompanyName == res.data.uCompanyName) {
          postapi('api/FireUserGroups/', {
            "id": 0,
            "uAccount": uAccount,
            "uGroupName": res.data.uGroupName,
            "uGroupId": res.data.uGroupId,
            "uCompanyName": res.data.uCompanyName
          }).then(res => {
            alert(res.data);
          })
        }
        else
          alert("加入失败，请检查公司名称和组织名称是否正确")
      }
      else {
        alert(res.data)
      }
    })
      .catch(function (ea) {
        alert("加入失败" + ea)
      })
  }
  else
    alert("小组名字不得少于5个字");
}

function create_group(uGroupName, uAccount, uCompanyName) {
  if (uGroupName != null && uGroupName.length >= 5 && uCompanyName && uCompanyName.length >= 5) {
    postapi("api/FireGroups", {
      uCreator: uAccount,
      uGroupName: uGroupName,
      uCompanyName: uCompanyName
    }).then(res => {

      alert(res.data);
    })
  }
  else
    alert("小组名字不得少于5个字");
}




function update_Info(info) {
  updateapi("api/FireUsers/" + info.uAccount, {
    uAccount: info.uAccount,
    uName: info.uName,
    uPhone: info.uPhone,
    uDegree: info.uDegree,
    uSchool: info.uSchool,
    uExpert: info.uExpert,
    uPlace: info.uPlace,
    uCompany: info.uCompany,
    uZhiwei: info.uZhiwei,
    uGangwei: info.uGangwei,
    uBirthDay: new Date(info.uBirthDay),
    uGDay: new Date(info.uGDay),
    uWorkDay: new Date(info.uWorkDay)
  }).then((res) => {
    res.data;
  })
}

async function get_info() {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_info'
    }
  )
  return memdata.data.data[0]
}

async function get_logininfo() {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_logininfo'
    }
  )
  return memdata.data.data[0]
}
async function get_silk() {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_silk'
    }
  )
  return memdata.data.data[0]
}

async function get_chars() {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_chars'
    }
  )
  return memdata.data.data
}

async function get_charitems(charid) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_charitems',
      data0: charid+''
    }
  )
  console.log(memdata)
  return memdata.data.data
}

async function get_charequips(charid) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_charequips',
      data0: charid+''
    }
  )
  console.log(memdata)
  return memdata.data.data
}

async function get_charpets(charid) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_charpets',
      data0: charid+''
    }
  )
  
  return memdata.data.data
}

async function get_petitems(charid,cosid) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_petitems',
      data0: charid+'',
      data1: cosid+''
    }
  )
  return memdata.data.data
}

async function sale_petitems(charid,cosid,charname) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'sale_petitems',
      data0: charid+'',
      data1: cosid+'',
      data2: charname+''
    }
  )
  alert(memdata.data)
}

async function refresh_rank() {
  const memdata = await postapi("sro/user",
    {
      apiname: 'refresh_rank'
    }
  )
  alert(memdata.data)
}

async function get_goods() {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_goods'
    }
  )  
  return memdata.data.data
}

async function get_silkgoods() {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_silkgoods'
    }
  )  
  return memdata.data.data
}

async function get_packgoods() {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_packgoods'
    }
  )
  return memdata.data.data
}

async function buy_packgoods(CharId,CharName,sType) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'buy_packgoods',
      data0: CharId+'',
      data1: CharName+'',
      data2:sType+''
    }
  )
  alert(memdata.data)
}

async function buy_good(CharId,CharName,sId) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'buy_good',
      data0: CharId+'',
      data1: CharName+'',
      data2:sId+''
    }
  )
  alert(memdata.data)
}

async function del_willgoods(CharId) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'del_willgoods',
      data0: CharId+''
    }
  )
  alert(memdata.data)
}

async function take_item(sId) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'take_item',
      data0: sId+''
    }
  )
  alert(memdata.data)
}

async function exchange_silk(CharId,CharName,Silk) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'exchange_silk',
      data0: CharId+'',
      data1: CharName+'',
      data2:Silk+''
    }
  )
  alert(memdata.data)
}

async function exchange_gold(CharId,CharName,Silk) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'exchange_gold',
      data0: CharId+'',
      data1: CharName+'',
      data2:Silk+''
    }
  )
  alert(memdata.data)
}

async function exchange_cash(CharId,CharName,Silk) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'exchange_cash',
      data0: CharId+'',
      data1: CharName+'',
      data2:Silk+''
    }
  )
  alert(memdata.data)
}


async function get_willgoods(charid) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_willgoods',
      data0: charid+''
    }
  )
  return memdata.data.data
}

function improve_money(money,min,max){
  if(money>max)
    return max
  else if(money <min)
    return min
  else 
    return money
}


async function get_buygoods(charid) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_buygoods',
      data0: charid+''
    }
  )
  return memdata.data.data
}

async function get_cfg(cfg_name) {
  const memdata = await postapi("sro/free",
    {
      apiname: 'get_cfg',
      data0: cfg_name+''
    }
  )
  return memdata.data.data
}

async function get_ranks() {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_ranks'
    }
  )
  return memdata.data.data
}

async function get_recala(charname) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_recala',
      data0:charname+''
    }
  )
  return memdata.data.data
}

async function get_recalb(charname) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'get_recalb',
      data0:charname+''
    }
  )
  return memdata.data.data
}

async function recal_money(charname,servername) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'recal_money',
      data0: charname+'',
      data1: servername+''
    }
  )
  alert(memdata.data)
}

async function money_gift(barcode) {
  const memdata = await postapi("sro/user",
    {
      apiname: 'money_gift',
      data0: barcode+''
    }
  )
  alert(memdata.data)
}


const pagename = async (doc,header)=>{
  let pageobj= await get_cfg('ServerName')
  let name= (pageobj[0]) ? ''+ header+'-'+pageobj[0].cCfgValue : '禅心丝路(一区)'+"]";
  doc.title =name;
} 

export default {
  join_group, create_group, update_Info, get_info, get_logininfo,
  get_silk, get_chars, get_charitems,get_charequips,get_charpets,get_petitems,get_goods,get_packgoods,
  buy_packgoods,get_willgoods,get_buygoods,del_willgoods,buy_good,take_item,get_silkgoods,improve_money,get_cfg,
  exchange_cash,exchange_silk,sale_petitems,refresh_rank,get_ranks,get_recala,recal_money,get_recalb,money_gift,
  exchange_gold,pagename
}
