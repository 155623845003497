<template>
  <Panel header="成员预览">
    <div>
      <div style="float: left;width: 7em;margin: 0.1em;" v-for="{ uName, uId, uAccount } in allmembers" :key="uId" @click="info.uId=uId;info.uAccount=uAccount;get_limit()">
        <div style="height: 3em;">
          <img src="img/amyelsner.png" style="width: 3em;height: 3em;border-radius: 50%;cursor: pointer;" />
        </div>
        <div style="height: 3em;" :title="uName">
          {{ uAccount }}
        </div>

      </div>
      <div class="clear"></div>
    </div>
  </Panel>
  <div class="place1em"></div>
  <Panel header="权限预览">
    <div>
      <div class="div_33_100 margin_top"> 
        <div class="p-inputgroup flex-1 ">        
          <span class="p-float-label">
              <InputText id="username" v-model="info.uId" :disabled="isEdit" @change="get_limit" />
              <label for="username">用户ID[0-全员]</label>
          </span>             
        </div>
      </div>
      
      <div class="div_33_100 margin_top">           
        <div class="p-inputgroup flex-1 ">        
          <span class="p-float-label">
              <InputText id="uPhone"  :disabled="isEdit" v-model="info.uAccount"/>
              <label for="uPhone">用户账号</label>
          </span>             
        </div>
      </div>

      
      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">        
          <span class="p-float-label">
              <InputText id="uDegree"  :disabled="isEdit" v-model="info.ApiName" @change="get_limit"/>
              <label for="uDegree">API名称</label>
              <Button icon="pi pi-plus" @click="add_limit"  title="个人信息填写正确的公司名称后，才可以加入小组" :disabled="info.ApiName==null||info.ApiName.length<5" v-if="info.Id&&info.Id<=0"/>
              <Button icon="pi pi-refresh" @click="upd_limit"  title="个人信息填写正确的公司名称后，才可以加入小组" v-if="info.Id&&info.Id>0"/>
              <Button icon="pi pi-times" @click="del_limit"  title="个人信息填写正确的公司名称后，才可以加入小组" v-if="info.Id&&info.Id>0"/>
               
          </span>             
        </div>
      </div>
      <div class="div_33_100 margin_top"> 
        <div class="p-inputgroup flex-1 ">        
          <span class="p-float-label">
              <InputText id="username" v-model="info.ApiType" :disabled="isEdit" @change="get_limit"/>
              <label for="username">API参数[Type='日记',可以为空]</label>
          </span>             
        </div>
      </div>
      
      <div class="div_33_100 margin_top">           
        <div class="p-inputgroup flex-1 ">        
          <span class="p-float-label">
              <InputText id="uPhone"  :disabled="isEdit" v-model="info.LimitTime" type="number"/>
              <label for="uPhone">时间限制(秒)[0-不限时间]</label>
          </span>             
        </div>
      </div>

      
      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">        
          <span class="p-float-label">
              <InputText id="LimitNum"  :disabled="isEdit" v-model="info.LimitNum" type="number"/>
              <label for="LimitNum">限制次数(次)[0-禁止，-1代表全部允许]</label>
              
          </span>             
        </div>
      </div>
      <div class="clear"></div>
      <div class="place1em"></div>
    </div>
    <div>
      <div style="margin: 0.5em;" v-for="(item) in alllimits" :key="item.Id" >
        <div style="text-align: left;">
          <img src="img/amyelsner.png" style="width: 3em;height: 3em;border-radius: 50%;float: left;cursor: pointer;" @click="info=item"/>
          <div style="margin-left: 4em;padding-top: 0.5em; height: 3em;" :title="uName">
            {{ item.uAccount=='0'?"所有用户":'用户['+item.uAccount+']' }}对{{ item.ApiName }}{{ item.ApiType.length>0?'('+item.ApiType+')':'' }}的权限是{{ Number(item.LimitTime)==0?'不限时间,':item.LimitTime+'秒内,'}}{{ Number(item.LimitNum)<0?'随便访问':Number(item.LimitNum)==0?'禁止访问':'最多访问'+item.LimitNum+"次" }}
          </div>
          <div class="clear"></div>
        </div>


      </div>

    </div>
  </Panel>
  <div class="place1em"></div>
  <Panel header="访问记录">
    <div>
      <div style="float: left;width: 7em;margin: 0.1em;" v-for="{ uName, uId, uAccount } in allmembers" :key="uId">
        <div style="height: 3em;">
          <img src="img/amyelsner.png" style="width: 3em;height: 3em;border-radius: 50%;" />
        </div>
        <div style="height: 3em;" :title="uName">
          {{ uAccount }}
        </div>

      </div>
      <div class="clear"></div>
    </div>
  </Panel>
</template>

<script setup>
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';  
import useManager from '@/services/useManager'
import { ref, onMounted } from "vue";
let allmembers = ref([]);
let alllimits = ref([]);
const get_myinfo = async () => {
  allmembers.value = await useManager.get_alluser();
  alllimits.value = await useManager.get_limits();
  
}

let info=ref({Id:-1,uId:0,uAccount:"0",ApiName:"",ApiType:"",LimitTime:0,LimitNum:0})
const add_limit = async () => {
  await useManager.add_limit(info.value);
  get_myinfo();
  get_limit();
} 
const upd_limit = async () => {
  await useManager.upd_limit(info.value);
  get_myinfo();
} 
const del_limit = async () => {
  await useManager.del_limit(info.value);
  get_myinfo();
  get_limit();
} 
const get_limit = async () => {
 let result=  await useManager.get_limit(info.value);
  if(result&&result.Id&&result.Id>0)
  {
    info.value=result;
  }
  else
  {
    info.value.Id=-1
  }
  
} 


onMounted(() => {
  get_myinfo();
  //alert(loginuser);
})






</script>
<style>
.header_line {
  border-top: 1px solid #c4c4c4;
  padding: 1em;
}
</style>

