import api from '@/services/useApi';
import useAlert from './useAlert';

const alert = useAlert.salert
const postapi = api.postapi;


async function setvalue(apiname,data, share) {
    {
        await postapi("slade/admin",
            {
                apiname: apiname,
                data: data,
                share: share
            }).then(res => {
                alert(res.data);
            })
    }
}
async function getvalues(apiname,data) {
    const memdata = await postapi("slade/admin",
        {
            apiname: apiname,
            data: data
        })
    if (memdata.data && memdata.data.data)
        return memdata.data.data
}
async function getvalue(apiname,data) {
   
    const memdata = await postapi("slade/admin",
        {
            apiname: apiname,
            data: data
        })

    if (memdata.data && memdata.data.data[0])
        return memdata.data.data[0]
   
}



const add_limit  =async(obj,share)     =>{return await setvalue("add_limit",obj,share)}
const upd_limit    =async(obj,share)     =>{return await setvalue("upd_limit",obj,share)}
const get_limit     =async(obj)     =>{return await getvalue("get_limit",obj)}
const get_limits     =async(obj)     =>{return await getvalues("get_limits",obj)}
const del_limit     =async(obj)     =>{return await setvalue("del_limit",obj)}

const get_alluser     =async(obj)     =>{return await getvalues("get_alluser",obj)}

export default {
    setvalue,getvalue,getvalues,
    add_limit,upd_limit,del_limit,get_limits,get_limit,
    get_alluser
}
