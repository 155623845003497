
<template>
    <div>
  
        <div id="scrollspy-collapsible">
        
            <div style="margin: 1em;padding: 1em;border-radius: 1em 1em;text-align: center;">
              <div style="font-weight: bold;">器件类型管理</div>
  
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="getobject.vName" :options="objoptions.vNames" editable optionLabel="vName"
                      placeholder="供应名称[中文/英文]" class="w-full md:w-14rem" @blur="get_sconfigs(getobject.vName)" />
                    <label for="vName">厂商名称[中文/英文]</label>
                  </span>
                </div>
              </div>
  
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="getobject.vStyle1" :options="objoptions.vStyle1s" editable optionLabel="vStyle1"
                      placeholder="器件主分类" class="w-full md:w-14rem" />
                    <label for="vStyle1">器件主分类</label>
                  </span>
                </div>
              </div>
  
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="getobject.vStyle2" :options="objoptions.vStyle2s" editable optionLabel="vStyle2"
                      placeholder="器件小分类" class="w-full md:w-14rem" />
                    <label for="vStyle2">器件小分类</label>
  
                  </span>
                </div>
              </div>
  
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="getobject.vPlace" :options="objoptions.vPlaces" editable optionLabel="vPlace"
                      placeholder="产地" class="w-full md:w-14rem" />
                    <label for="vPlace">产地</label>
  
                  </span>
                </div>
              </div>
  
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="getobject.vPage" :options="objoptions.vPages" editable optionLabel="vPage"
                      placeholder="主页" class="w-full md:w-14rem" />
                    <label for="vPage">主页</label>
  
                  </span>
                </div>
              </div>
              <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue" :sharedata="getshare"></ObjectShare>
  
              <div class="clear"></div>
  
              <div class="place1em"></div>
              <div>
                <Button icon="pi pi-plus" class="margin" title="上传资料" label="新增" @click="create_blog()" />
                <Button icon="pi pi-refresh" class="margin" title="更新资料" label="更新" @click="update_blog(getobject.pid)" />
                <Button icon="pi pi-times" class="margin" title="删除资料" label="删除" @click="delete_blog(getobject.pid)" />
                <Button icon="pi pi-times" class="margin" title="显示列表" label="显示" @click="start_show" />
              </div>
              <div class="place1em"></div>
              <div v-if="isDisplay">
                <div @click="getobject.vId = vId; search_blog()"
                  style="padding: 0.5em;text-align: left;cursor: pointer;border: 1px solid #efefef; margin: 0.1em; border-radius: 0.5em;width: auto;float: left;"
                  v-for="{ vName, vId, vStyle1, vStyle2,vPlace } in allgroups" :key="vId">
                  <a :title="vId+vPlace"><i style="font-weight: bold;font-size: large;">{{ vStyle2 }}</i>(<i style="font-size: small;">{{ vName }},{{ vStyle1 }}</i>)</a>
                </div>
              </div>
              <div class="clear"></div>
              <div class="place1em"></div>
            </div>
          </div>
        </div>
        <div style="height: 1em;"></div>
      
  </template>
    
  <script setup>
  import { ref, onMounted, defineEmits } from "vue";
  import Button from 'primevue/button';
  import Dropdown from 'primevue/dropdown';
  import useCommon from '@/services/useCommon';
  import useSlade from "@/services/useSlade";
  import useAlert from "@/services/useAlert";
  
  import ObjectShare from '@/components/ObjectShare.vue';
  let getobject = ref({});
  let objoptions = ref({})
  let sendshare = ref({});
  let getshare = ref({});
  sendshare.value.Id = -1
  const getrolevalue = (obj) => {
    sendshare.value = obj
  }
  const get_configs = async () => {
    objoptions.value.vNames = await useSlade.get_styles_n();
  }
  
  const get_sconfigs = async (obj) => {
    if(obj&&obj.vName)
    {
      objoptions.value.vPages = await useSlade.get_vpages(obj);
      if (objoptions.value.vPages.length > 0)
        getobject.value.vPage = objoptions.value.vPages[0]
      objoptions.value.vPlaces = await useSlade.get_vplaces(obj);
      if (objoptions.value.vPlaces.length > 0)
        getobject.value.vPlace = objoptions.value.vPlaces[0]
    }
  }
  
  let isDisplay = ref(false)
  
  let allgroups = ref({})
  
  onMounted(() => {
    get_configs();
  })
  
  const start_show = async () => {
    if (!isDisplay.value)
      allgroups.value = await useSlade.get_styles();
    isDisplay.value = ~isDisplay.value
  }
  
  const beforepost = (obj) => {
    if (obj.vName)
      obj.vName = useCommon.o_s(obj.vName, "vName")
    if (obj.vStyle1)
      obj.vStyle1 = useCommon.o_s(obj.vStyle1, "vStyle1")
    if (obj.vStyle2)
      obj.vStyle2 = useCommon.o_s(obj.vStyle2, "vStyle2")
    if (obj.vPage)
      obj.vPage = useCommon.o_s(obj.vPage, "vPage")    
    if (obj.vPlace)
      obj.vPlace = useCommon.o_s(obj.vPlace, "vPlace")
  }
  
  const create_blog = async () => {
    let postgroup = useCommon.o_o(getobject.value)
    beforepost(postgroup);
    if (getobject.value.vName && getobject.value.vStyle1 && getobject.value.vStyle2 && getobject.value.vPlace) {
      await useSlade.add_style(postgroup, sendshare.value);
      search_blog();
    }
    else
      useAlert.salert("数据不能为空")
  }
  
  const update_blog = async () => {
    let postgroup = useCommon.o_o(getobject.value)
    beforepost(postgroup);
    if (getobject.value.vName && getobject.value.vStyle1 && getobject.value.vStyle2 && getobject.value.vPlace) {
      await useSlade.upd_style(postgroup, sendshare.value);
      search_blog();
    }
    else
      useAlert.salert("数据不能为空")
  }
  
  const delete_blog = async () => {
    let postgroup = useCommon.o_o(getobject.value)
    beforepost(postgroup);
    await useSlade.del_style(postgroup, sendshare);
    search_blog();
  
  }
  
  const search_blog = async () => {
    let postgroup = useCommon.o_o(getobject.value)
    beforepost(postgroup);
    let getresult = await useSlade.get_style(postgroup);
    if (getresult && getresult.vName) {
      getshare.value = getresult
      getobject.value = getresult
    }
    else {
      getobject.value.vId = -1;
    }
    ondatachange();
    allgroups.value = await useSlade.get_styles();
  }
  
  const emits = defineEmits(['ondatachange']);
  function ondatachange() {
    emits("ondatachange")
  }
  </script>
  
    