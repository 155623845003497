/*该控件为通用的树形显示控件,可以进行数据展示,数据更新，数据删除。*/
<template>
    
    <div v-for="{ index } in mydata" :key="index">
        <div style="text-align:left" v-if="(mydata[index]) && !(!mydata[index].isEdit && (!mydata[index].isChange))">
            <div
                style="font-size: 1em;margin-top: 1em;font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;">
                <div class="margin">
                    <span class="p-float-label margin_top div_100">
                        <InputText id="plan" placeholder="目标" maxlength="30" style="width: 100%;"
                            v-model="mydata[index].pPlan" />
                        <label for="plan" style="color: white;">事务描述</label>
                    </span>

                </div>
                <div class="clear"></div>

                <div style="height: 1px;overflow: hidden;">
                    <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue(index, $event)"
                        :sharedata="mydata[index]" :setroledata="props.setroledata" disabled labelstyle="white">
                    </ObjectShare>
                    <div class="clear"></div>
                </div>
                <div class="clear"></div>
                <div style="text-align: center;padding-top: 0.8em;">
                    <i class="icon_btn pi pi-times" @click="del_childplan(mydata[index].index, mydata, mydata[index].pId);"
                        :title="'删除事项' + index" v-if="mydata[index].isEdit"></i>
                    <i class="pi pi-check" style="cursor: pointer;margin: 0.5em;"
                        v-bind:title="'父项目ID为' + mydata[index].pParentId" @click="add_plan(mydata[index])"
                        v-if="mydata[index].isEdit && mydata[index].pPlan.length > 0"></i>
                    <i class="pi pi-lock" style="cursor: pointer;margin: 0.5em;" v-bind:title="mydata[index].index"
                        @click="mydata[index].isChange = true"
                        v-if="!mydata[index].isEdit && (!mydata[index].isChange)"></i>
                    <i class="pi pi-lock-open" style="cursor: pointer;margin: 0.5em;" v-bind:title="mydata[index].pId"
                        @click="upd_plan(mydata[index])" v-if="mydata[index].isChange"></i>
                </div>
            </div>
        </div>

        <div style="text-align:left;position: relative;" v-else>
            <div style="font-size: 1em;margin-top: 1em;min-height: 3em;border-top: 1px rgb(232, 231, 231) solid" v-if="(mydata[index])">
                <div
                    style="background-image: url('/img/User04.png');width: 60px; height: 60px; border-radius: 50% 50%;background-size: 100% 100%;float: left;">

                </div>
                <div style="margin-left: 80px;padding-top: 0.5em;">
                    <div>
                        <div style="float: left;width: 50%;"><i class="pi pi-home"></i> {{ mydata[index].pStart }}</div>
                        <div style="float: left;width: 50%;text-align: right;">
                            <div v-if="false">
                                <i class="icon_btn pi pi-times"
                                    @click="del_plan(mydata[index].index, mydata, mydata[index].pId);"
                                    :title="'删除事项' + mydata[index].pId" v-if="!mydata[index].is_ededit">删除</i>
                                <i class="icon_btn pi pi-reply"
                                    @click="add_child(mydata[index], props.pstyle); mydata[index].isChildShow = true;">回复</i>
                                <i class="icon_btn pi pi-lock" title="修改" @click="mydata[index].isChange = true;"
                                    v-if="!mydata[index].isEdit && (!mydata[index].isChange) && !mydata[index].is_ededit">修改</i>
                                <i class="icon_btn pi pi-lock-open" title="保存修改"
                                    @click="upd_plan(mydata[index].pId, mydata[index])"
                                    v-if="mydata[index].isChange && !mydata[index].is_ededit"></i>
                                <i class="icon_btn pi pi-sign-out" v-if="mydata[index].is_ededit"
                                    v-bind:title="'退出编辑' + mydata[index].pId"
                                    @click="mydata[index].is_ededit = false; mydata[index].isEdit = false" />

                                <i class="icon_btn pi pi-check-circle"
                                    @click="finish_plan(mydata[index].index, mydata, mydata[index].pId);;"
                                    :title="'完成该项目' + mydata[index].pId"
                                    v-if="mydata[index].today && mydata[index].today.length > 0 && (!mydata[index].is_ededit)">审核</i>
                            </div>

                        </div>
                    </div>
                    <div>
                        <i class="pi pi-star"></i> {{ mydata[index].pPlan }}
                    </div>
                </div>
                <div class="card_header" style="width: 50%;text-align: left;" :title="mydata[index].group_name">
                    <i class="pi pi-chevron-circle-left card_key" @click="mydata[index].isChildShow = true;" title="展开子项目"
                        v-if="(!mydata[index].isChildShow) && mydata[index].children && mydata[index].children.length > 0"></i>
                    <i class="pi pi-chevron-circle-down card_key" @click="mydata[index].isChildShow = false" title="合拢子项目"
                        v-if="mydata[index].isChildShow && mydata[index].children && mydata[index].children.length > 0"></i>


                </div>

                <div class="clear"></div>
            </div>
            <div v-if="mydata[index]">
                <div v-if="mydata[index].children && mydata[index].isChildShow && mydata[index].children.length > 0">
                    <PlanMsg :isparent="mydata[index].pId" :datas="mydata[index].children" :userid="userid"
                        :setroledata="props.setroledata" :pstyle="props.pstyle" @datachange="datachange"></PlanMsg>
                </div>
            </div>
            
        </div>

    </div>
    <div class="clear"></div>

    <div style="text-align: center;padding: 0.5em;" v-if="props.pstyle != 'MyFinished'">
        <i class="nicon_btn pi pi-comment" :title="props.pheader" @click="add_input(mydata, props.pstyle)"
            v-if="isparent < 0">{{ props.pheader }}</i>

    </div>
    <div class="place1em"></div>
</template>
<script setup>
import InputText from 'primevue/inputtext';
import { defineProps, defineEmits, defineExpose } from 'vue';
import ObjectShare from './ObjectShare.vue';
import useCommonMap from '@/services/useCommonMap'

const props = defineProps({
    isparent: Number,
    datas: Array,
    userid: Number,
    pstyle: String,
    pheader: String,
    setroledata: Object,
    belonginfo: Object
});

const { mydata, getrolevalue, add_plan, upd_plan,
    del_plan, del_childplan, add_child, add_input,
    finish_plan, getaffairs } = useCommonMap(props)


const emits = defineEmits(['datachange']);
async function datachange() {
    console.log(props.isparent, "datachange")
    emits("datachange")
    await getaffairs()
}

defineExpose({
    getaffairs
});

</script>