<template>
  
  <div v-if="!isEdit">    
    <div style="height: 1.5em;"></div>
    <div>
      <SearchBlock :sname="'项目'" @onsearch="filters" :flabel="filter_arr"></SearchBlock>
    </div>
    <div class="padding" style="min-height: 1500px;">
      <div class="blog_outline" v-for="{ pId, pName, pLine, pStyle, pState, pGroupInfo, pImg, CreateTime, pProgress } in blogs"
        :key="pId" style="position:relative;">
        <div v-if="blogs && blogs.length > 0" class="blog">
          <div :style="'height: 8em;background-image:' + pImg + ';'" class="blog_img">
            <Knob :show-value="true" :model-value="pProgress" valueTemplate="{value}%" />
            <!-- <img :src=" (bImgUrl&&bImgUrl.length>0)?bImgUrl.replace('url(','').replace(')',''):''" top alt="..." style="width: 100%;height: 100%;z-index: -99;"/> -->
          </div>
          <div style="height: 7em;padding: 0.5em;text-align: left;">
            <a :href="'/project/' + pId" @click="page_url = bId" style="font-weight: bold;color: #4d4b4b;">{{ pName }}</a>
            <div style="height: auto;text-align:left;color: #a6a2a2;font-size:0.8em !important;overflow:hidden;">
              <div><i class="pi pi-users"></i> {{ JSON.parse(pGroupInfo).uCompanyName }}</div>
              <div><i class="pi pi-users"></i> {{ JSON.parse(pGroupInfo).uGroupName }}</div>
              <div><i class="pi pi-bookmark"></i> {{ pLine }} - {{ pStyle }}</div>
              <div><i class="pi pi-user"></i> {{ pState }} </div>
            </div>
            <div style="padding: 0.5em;font-size: 15px !important;">

            </div>
          </div>
          <div style="padding: 0.5em;">
            <div>
              <div style="float: left;width: 8em;font-size: 15px !important;">
                <small class="text-muted"><i class="pi pi-calendar-plus"></i> {{ CreateTime.slice(0, 10) }} </small>
              </div>

              <div style="float: left;width: 8em;font-size: 15px !important;">
                <small class="text-muted"> <i class="fas fa-star"></i> {{ pState }} </small>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>

        </div>
      </div>
      <div class="clear"></div>
      <div style="height: 3em;"></div>


    </div>
  </div>
  <div v-else>
    
    <ProjectEdit @ondatachange="listshow" :blog_id="projectname"></ProjectEdit>
  </div>
</template>
    
<script setup>
import ProjectEdit from "./ProjectEdit.vue";
import useBlog from '@/services/useBlog';
import useApi from "@/services/useApi";
import { ref, onMounted, defineProps } from "vue";
import { useStore } from "vuex";
import Knob from 'primevue/knob';
import useSlade from "@/services/useSlade";
import SearchBlock from "@/components/SearchBlock.vue";

const props = defineProps({
  filter: String,
  project_id: String
})
let isEdit = ref(false)
let projectname = ref('')
const store = useStore();
let authed = ref(store.state.authed);
let isadmin = ref(false);
let blogs = ref([])
let local_blogs = ref([])
const filter_arr = [{ name: '名称', type: 'fName' }, { name: '标签', type: 'fLabel' }]
const filters = (name, type) => {
  blogs.value = useApi.search_item(name, type, local_blogs.value)
}
const listshow = async () => {
  blogs.value = await useSlade.get_projects()

  console.log(blogs.value)
  local_blogs.value = blogs.value

}

const get_role = async () => {
  let role_data = await useBlog.get_role();
  isadmin.value = (role_data == 'SysAdmin')
}

const switch_edit = () => {
  if (isEdit.value) {
    isEdit.value = false;
    projectname = '';
    window.location.href = '/projects'
  }
  else {
    isEdit.value = true;
  }
}
const my_bt1 = {
          label: '编辑项目',
          icon: 'pi pi-lock',
          command: () => {
            switch_edit()
          }
      }

onMounted(() => {
  listshow();
  if (authed.value){
    get_role()
    useApi.add_menus(my_bt1)
  }
  if (props.filter && props.filter.length > 0) {
    filters()
  }
  if (props.project_id) {
    projectname.value = props.project_id
    isEdit.value = true;
  }

})
</script>
    