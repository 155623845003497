
<template>
  <div>
    <div id="scrollspy-collapsible">
      <div class="card">
        <div>
          <div style="margin: 1em;padding: 1em;border-radius: 1em 1em;">
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <Dropdown v-model="myobject.EDAType" editable :options="myother.Style1s" optionLabel="EDAType"
                    placeholder="大类型" @change="get_styles_ss(myobject.Style1)" class="w-full md:w-14rem" />
                  <label for="companyname">EDA工具</label>
                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.Name" @change="search_blog(myobject)" />
                  <label for="companyname">PartNumber</label>
                  <Button icon="pi pi-search" title="规格书名称" @click="search_blog(myobject)" />
                </span>
              </div>
            </div>

            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.FileName" @change="search_ds(myobject)" />
                  <label for="companyname">规格书名称</label>
                  <Button icon="pi pi-search" title="规格书名称" @click="search_ds(myobject)" />
                </span>
              </div>
            </div>
            

            <div class="margin_top div_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.Description" disabled />
                  <label for="companyname">器件描述</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.SCH"  />
                  <label for="companyname">SCH封装名称</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.PCB"  />
                  <label for="companyname">PCB封装名称</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.D3D"  />
                  <label for="companyname">3D封装名称</label>
                </span>
              </div>
            </div>
            <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue" :sharedata="getshare"></ObjectShare>
            <div class="clear"></div>
            <div class="place1em"></div>
            <div>
              <Button icon="pi pi-plus" v-if="myobject.Id <= 0" class="margin" title="上传资料" label="新增"
                @click="create_blog()" />
              <Button icon="pi pi-refresh" v-if="myobject.Id > 0" class="margin" title="更新资料" label="更新"
                @click="update_blog()" />
              <Button icon="pi pi-times" v-if="myobject.Id > 0" class="margin" title="删除资料" label="删除"
                @click="delete_blog()" />
              <Button icon="pi pi-refresh" class="margin" v-if="myobject.FullPath && myobject.FullPath.endsWith('.pdf')"
                title="预览规格书仅限pDF" label="预览" @click="isdisplay = ~isdisplay" />
            </div>
            <div class="place1em"></div>
            <PdfReader :pdfid="myobject.Id" v-if="isdisplay"></PdfReader>
            <div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 1em;"></div>
      <div data-mdb-spy="scroll" data-mdb-target="#scrollspy-collapsible" data-mdb-offset="0" class="scrollspy-example">

        <editor v-model="myobject.EDARecord" id="EditPanel1" :init="useEditor.init_old" />
      </div>
      <div style="height: 1em;"></div>
    </div>

  </div>
</template>
    
<script setup>
import Editor from '@tinymce/tinymce-vue'
import ObjectShare from '@/components/ObjectShare.vue'
import { ref, onMounted, defineEmits } from "vue";
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import useCommon from '@/services/useCommon';
import useSlade from "@/services/useSlade"
import PdfReader from './PdfReader.vue';
import useEditor from '@/services/useEditor';
import useAlert from '@/services/useAlert';
let myobject = ref({});
let myother = ref({})
let sendshare = ref({});
let getshare = ref({});
sendshare.value.Id = -1
const getrolevalue = (obj) => {
  sendshare.value = obj
}
const get_configs = async () => {
  myother.value.Projects = await useSlade.getcs_projects();
  myother.value.Style1s = await useSlade.get_edatypes();
}

const get_styles_ss = async (obj) => {
  myother.value.Style2s = await useSlade.get_styles_ss(obj)
}

const get_styles_v = async (obj) => {
  myother.value.Manufactures = await useSlade.get_styles_v(obj)
}

onMounted(() => {
  get_configs();
})

let isdisplay = ref(false);

const beforepost = (obj) => {
  if (obj.EDAType)
    obj.EDAType = useCommon.o_s(obj.EDAType,"EDAType")
  if (obj.SCH)
    obj.SCH = useCommon.jsono_s(obj.SCH)
  if (obj.PCB)
    obj.PCB = useCommon.jsono_s(obj.PCB)
  if (obj.D3D)
    obj.D3D = useCommon.jsono_s(obj.D3D)
  if (obj.Project)
    obj.Project = useCommon.jsono_s(obj.Project)
  if (obj.Style1)
    obj.Style1 = useCommon.jsono_s(obj.Style1)
  if (obj.Style2)
    obj.Style2 = useCommon.jsono_s(obj.Style2)
  if (obj.Manufacture)
    obj.Manufacture = useCommon.jsono_s(obj.Manufacture)
}

const create_blog = async () => {
  if (myobject.value.Name && myobject.value.Description && myobject.value.SCH && myobject.value.PCB && myobject.value.D3D) {
    let postgroup = useCommon.o_o(myobject.value)
    beforepost(postgroup);
    await useSlade.add_eda(postgroup, sendshare.value);
    search_blog();
    ondatachange()
  }
  else
    useAlert.salert("信息不完整")
}

const update_blog = async () => {
  if (myobject.value.Name && myobject.value.Description && myobject.value.SCH && myobject.value.PCB && myobject.value.D3D) {
    let postgroup = useCommon.o_o(myobject.value)
    beforepost(postgroup);
    await useSlade.upd_eda(postgroup, sendshare.value);
    search_blog();
    ondatachange()
  }
  else
    useAlert.salert("信息不完整")
}

const delete_blog = async () => {
  let postgroup = useCommon.o_o(myobject.value)
  beforepost(postgroup);
  await useSlade.del_eda(postgroup, sendshare);
  search_blog();
  ondatachange()
}

const search_blog = async () => {
  let postgroup = useCommon.o_o(myobject.value)
  beforepost(postgroup);
  let getresult = await useSlade.get_eda(postgroup);
  if (getresult && getresult.FileName) {
    getshare.value = getresult
    myobject.value = getresult
    myobject.value.Project = useCommon.jsons_o(myobject.value.Project)
    myobject.value.Style1 = useCommon.jsons_o(myobject.value.Style1)
    get_styles_ss(myobject.value.Style1)
    myobject.value.Style2 = useCommon.jsons_o(myobject.value.Style2)
    get_styles_v(myobject.value.Style2)
    myobject.value.Manufacture = useCommon.jsons_o(myobject.value.Manufacture)
    myobject.value.EDAType = useCommon.ss_o(myobject.value.EDAType,"EDAType")
    myobject.value.EDARecord = useCommon.o_s(myobject.value.EDARecord,"EDARecord")
  }
  else {
    myobject.value.Id = -1;
  }

  ondatachange()
}

const search_ds = async () => {
  let postds = { FileName: myobject.value.FileName }
  let getresult = await useSlade.get_ds(postds);
  if (getresult && getresult.FileName) {
    getshare.value = getresult
    myobject.value = getresult
    myobject.value.Id = -1;
  }
  ondatachange()
}



const emits = defineEmits(['ondatachange']);
function ondatachange() {
  emits("ondatachange")
}
</script>
  
    