/*该控件为通用的树形显示控件,可以进行数据展示,数据更新，数据删除。*/
<template>
    <div v-if="props.pstyle == 'MyFinished'">
        <span class="p-float-label margin_top">
            <Calendar id="planstart" date-format="yy/mm/dd" style="width: 100%;" :manualInput="true" showButtonBar
                placeholder="选择时间" v-model="sel_day.pStart" v-on:update:model-value="getoneday(sel_day)" />
            <label for="planstart" style="color: white;">选择时间</label>
        </span>
        <div class="place1em"></div>        
    </div>

    <div v-for="{ index } in mydata" :key="index" :class="'box_blog box' + ((index % 6) + 1)">
        <div style="text-align:left" v-if="(mydata[index]) && !(!mydata[index].isEdit && (!mydata[index].isChange))">
            <div
                style="font-size: 1em;margin-top: 1em;font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;">
                <div class="margin">
                    <span class="p-float-label margin_top div_100">
                        <InputText id="plan" placeholder="目标" maxlength="30" style="width: 100%;"
                            v-model="mydata[index].pPlan" />
                        <label for="plan" style="color: white;">事务描述</label>
                    </span>
                </div>
                <div class="clear"></div>                
                <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue(index, $event)" :sharedata="mydata[index]"
                    :setroledata="props.setroledata"  labelstyle="white"></ObjectShare>                
                <div class="clear"></div>
                <div style="text-align: center;padding-top: 0.8em;">
                    <i class="icon_btn pi pi-times" @click="del_childplan(mydata[index].index, mydata, mydata[index].pId);"
                        :title="'删除事项' + index" v-if="mydata[index].isEdit"></i>
                    <i class="pi pi-check" style="cursor: pointer;margin: 0.5em;"
                        v-bind:title="'父项目ID为' + mydata[index].pParentId" @click="add_plan(mydata[index])"
                        v-if="mydata[index].isEdit && mydata[index].pPlan.length > 0"></i>
                    <i class="pi pi-lock" style="cursor: pointer;margin: 0.5em;" v-bind:title="mydata[index].index"
                        @click="mydata[index].isChange = true"
                        v-if="!mydata[index].isEdit && (!mydata[index].isChange)"></i>
                    <i class="pi pi-lock-open" style="cursor: pointer;margin: 0.5em;" v-bind:title="mydata[index].pId"
                        @click="upd_plan(mydata[index])" v-if="mydata[index].isChange"></i>
                </div>
            </div>
        </div>

        <div style="text-align:left;position: relative;" v-else>
            <div style="font-size: 1em;margin-top: 1em;min-height: 3em;" v-if="(mydata[index])">
                <div style="font-weight: bold;color:rgb(244, 245, 244);font-size:1.1em" :title=" mydata[index].pBelongInfo.uGroupName+'@'+ mydata[index].pBelongInfo.uCompanyName ">
                    <div style="width: 4%;float: left;">
                        <div v-if="mydata[index].childrenlen>0">
                            <i class="pi pi-chevron-circle-right" @click="getaffairslayer(mydata[index],mydata[index].pId);mydata[index].isChildShow = true;" title="展开子项目2" v-if="(!mydata[index].isChildShow)"></i>
                            <i class="pi pi-chevron-circle-down" @click="mydata[index].isChildShow = false" title="合拢子项目" v-else></i>
                        </div>
                        <div v-else>
                            <i class="pi pi-book" title="菜单"></i>
                        </div>
                    </div>
                    <div style="width: 60%;float: left;text-align: left;" :title="mydata[index].group_name">
                        <a  v-if="mydata[index].childrenlen>0" style="color: white;" :href="'/nblogs/blog_'+mydata[index].pId">{{ mydata[index].pPlan }}({{mydata[index].childrenlen}})</a>
                        <a  v-else style="color: white;" :href="'/nblogs/blog_'+mydata[index].pId">{{ mydata[index].pPlan }}</a>
                    </div>
                    <div style="width: 36%;float: left;text-align: left;">
                        <div style="text-align: center;" v-if="mydata[index] && mydata[index].canEdit == 1">                        
                            <div>
                                <i class="icon_btn pi pi-minus" @click="del_plan(mydata[index].index, mydata, mydata[index].pId);"
                                    :title="'删除事项' + mydata[index].pId" v-if="!mydata[index].is_ededit"></i>
                                <i class="icon_btn pi pi-plus"
                                    @click="add_child(mydata[index], props.pstyle); mydata[index].isChildShow = true;" title="增加子项目"
                                    v-if="!mydata[index].is_ededit"></i>
                                <i class="icon_btn pi pi-lock" title="修改" @click="mydata[index].isChange = true;"
                                    v-if="!mydata[index].isEdit && (!mydata[index].isChange) && !mydata[index].is_ededit"></i>
                                <i class="icon_btn pi pi-lock-open" title="保存修改" @click="upd_plan(mydata[index].pId, mydata[index])"
                                    v-if="mydata[index].isChange && !mydata[index].is_ededit"></i>
                                
                                <i class="icon_btn pi pi-sign-out" v-if="mydata[index].is_ededit"
                                    v-bind:title="'退出编辑' + mydata[index].pId"
                                    @click="mydata[index].is_ededit = false; mydata[index].isEdit = false" />
                                <i class="icon_btn pi pi-check-circle"
                                    @click="finish_plan(mydata[index].index, mydata, mydata[index].pId);;"
                                    :title="'完成该项目' + mydata[index].pId"
                                    v-if="mydata[index].today && mydata[index].today.length > 0 && (!mydata[index].is_ededit)"></i>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <div v-if="mydata[index]">
                <div v-if="mydata[index].children && mydata[index].isChildShow && mydata[index].children.length > 0">
                    <CommonBlogMenu :isparent="mydata[index].pId" :datas="mydata[index].children" :userid="userid" :setroledata="props.setroledata" :pstyle="props.pstyle" @datachange="datachange"></CommonBlogMenu>
                </div>               
            </div>
        </div>

    </div>
    <div class="clear"></div>
    <div style="text-align: center;padding: 0.5em;" v-if="props.pstyle != 'MyFinished'">
        <i class="nicon_btn pi pi-comment" :title="props.pheader" @click="add_input(mydata, props.pstyle)" v-if="isparent < 0">{{ props.pheader }}</i>
    </div>
    <div class="place1em"></div>
</template>
<script setup>
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import { defineProps,defineEmits,defineExpose } from 'vue';
import ObjectShare from './ObjectShare.vue';
import useCommonMap from '@/services/useCommonMap'
const props = defineProps({
    isparent: Number,
    datas: Array,
    userid: Number,
    pstyle: String,
    pheader: String,
    setroledata: Object,
    belonginfo: Object
});

const { mydata,  sel_day, getrolevalue, add_plan, upd_plan,
    del_plan, del_childplan,  add_child, add_input,  
    finish_plan,   getoneday, getaffairs,getaffairsbyid } = useCommonMap(props)


const emits = defineEmits(['datachange']);
async function datachange() {
    console.log(props.isparent,"datachange")
    emits("datachange")
    await getaffairs()
}

async function getaffairslayer(oldobj,pid) {  
    console.log(pid)  
    oldobj.children= await getaffairsbyid(pid)
}

defineExpose({
    getaffairs
});

</script>
<style>
    .box_blog {
      padding: 0.1em 1em 0.1em 1em; 
      margin-bottom: 0.5em;
      color: white;
      border-radius: 1em;
      border: 1px solid white;
    
    }
</style>