
<template>
    <div class="about">
  
      <div :class="oclass">
        <FileUpload mode="basic" name="demo[]" accept="image/*" auto :maxFileSize="2000000"
          :style="{ 'width': '100%', 'height': '15em', 'background-size': 'cover', 'background-image': blog.bImgUrl }"
          customUpload @uploader="customBase64Uploader" />
        <div id="scrollspy-collapsible" style="padding: 0.5em;">
          
          <div class="place1em"></div>
  
  
          <div class="card">
            <div style="position: relative;">
  
            </div>
            <div>
              <div class="card-body">
                <div>
                  <div class="div_33_100 margin_top">
                    <div class="p-inputgroup flex-1 ">
                      <span class="p-float-label">
                        <Dropdown v-model="blog.bContent" :options="blogoptions.bContents" option-label="bContent" editable
                          @change="get_bsconts" placeholder="一级目录" class="w-full md:w-14rem" :loading="false" />
                        <Button icon="pi pi-map" @click="$router.replace({ name: 'blogmap' })" title="编辑笔记地图" />
                        <label for="uGangwei">一级目录</label>
                      </span>
                    </div>
                  </div>
                  <div class="div_33_100 margin_top">
                    <div class="p-inputgroup flex-1 ">
                      <span class="p-float-label">
                        <Dropdown v-model="blog.bsContent" :options="blogoptions.bsContents" option-label="bsContent"
                          @change="get_names" editable placeholder="二级目录" class="w-full md:w-14rem" :loading="false" />
                        <label for="uGangwei">二级目录</label>
                      </span>
                    </div>
                  </div>
                  <div class="div_33_100 margin_top">
                    <div class="p-inputgroup flex-1 ">
                      <span class="p-float-label">
                        <Dropdown v-model="blog.bName" editable placeholder="笔记标题" max="10" class="w-full md:w-14rem"
                          :options="blogoptions.bNames" option-label="bName" :loading="false" @change="search_blog()" />
                        <Button icon="pi pi-search" title="点击生成笔记ID" @click="search_blog()" />
                        <label for="uGangwei">笔记标题</label>
                      </span>
                    </div>
                  </div>
                  <div class="div_33_100 margin_top">
                    <div class="p-inputgroup flex-1 ">
                      <span class="p-float-label">
                        <InputText placeholder="笔记ID[根据名字自动生成]" disabled v-model="blog.Id" />
                        <label for="uGangwei">笔记ID[{{ blog.NID }}]</label>
                      </span>
                    </div>
                  </div>
                  <div class="div_33_100 margin_top">
                    <div class="p-inputgroup flex-1 ">
                      <span class="p-float-label">
                        <InputText placeholder="标签[关键字]" :disabled="false" v-model="blog.bKeyWords" />
                        <label for="uGangwei">标签[关键字]</label>
                      </span>
                    </div>
                  </div>
  
                  <div class="div_33_100 margin_top">
                    <div class="p-inputgroup flex-1 ">
                      <span class="p-float-label">
                        <Dropdown v-model="blog.bUrl" :options="blogoptions.bUrls" optionLabel="bUrl" placeholder="文章类型"
                          class="w-full md:w-14rem" :loading="false" />
                        <label for="uGangwei">文章类型</label>
                      </span>
                    </div>
                  </div>

                  <div class="div_100 margin_top">
                    <div class="p-inputgroup flex-1 ">
                      <span class="p-float-label">
                        <InputText placeholder="笔记ID" :disabled="false" v-model="menu_block.pFiles" />
                        <label for="uGangwei">笔记ID</label>
                      </span>                      
                      <span class="p-float-label">
                        <InputText placeholder="目录节点" :disabled="false" v-model="menu_block.pId" />
                        <label for="uGangwei">目录节点</label>
                        <Button icon="pi pi-lock" title="绑定目录" @click="bind_nblog()" />
                      </span>                      
                    </div>                    
                  </div>

                  <div class="div_100 margin_top">
                    <div class="p-inputgroup flex-1 ">
                      <span class="p-float-label">
                        <InputText placeholder="描述[SEO]" :disabled="false" v-model="blog.bDescription" />
                        <label for="uGangwei">描述[SEO]</label>
                      </span>
                    </div>
                  </div>
                  <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue" :sharedata="getshare"></ObjectShare>
                </div>
              </div>             
              <div style="text-align: left;padding: 1em;">
  
              </div>
            </div>
  
          </div>
  
          <div style="height: 1em;"></div>
  
        </div>
      </div>
      <div class="div_100">
        <!-- Spied element -->
        <div data-mdb-spy="scroll" data-mdb-target="#scrollspy-collapsible" data-mdb-offset="0" class="scrollspy-example"
          style="padding: 0.5em;">
  
          <editor v-model="blog.bText" id="EditPanel1" :init="useEditor.init_old" />
  
        </div>
        <!-- Spied element -->
      </div>
      <div class="clear"></div>
  
  
    </div>
  </template>
  
  <script setup>
  import Editor from '@tinymce/tinymce-vue'
  import { ref, defineProps, onMounted,onBeforeUnmount } from "vue";
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  import Dropdown from 'primevue/dropdown';
  import FileUpload from 'primevue/fileupload';
  import useCommon from '@/services/useCommon';
  import useApi from '@/services/useApi';
  import ObjectShare from '@/components/ObjectShare.vue';
  import useSlade from '@/services/useSlade'
  import useAlert from '@/services/useAlert';
  import useEditor from '@/services/useEditor'
  
  let blog = ref({});
  blog.value.bImgUrl = 'url(/images/f2.jpg)';
  let blogoptions = ref({})
  let sendshare = ref({});
  let getshare = ref({});
  sendshare.value.Id = -1
  const getrolevalue = (obj) => {
    sendshare.value = obj
  }
  const my_bts = [
    {
        label: '增加博客',
        icon: 'pi pi-plus',
        command: () => {
          blog.value.Id = blog.value.NID; 
          create_blog()
        }
    },
    {
        label: '更新博客',
        icon: 'pi pi-refresh',
        command: () => {
          update_blog()
        }
    },
    {
        label: '编辑标题',
        icon: 'pi pi-lock',
        command: () => {
          openclass()
        }
    },
    {
        label: '修改名字',
        icon: 'pi pi-book-o',
        command: () => {
          update_blog();useSlade.upd_plan(menu_block,sendshare);
        }
    },
    {
        label: '删除博客',
        icon: 'pi pi-trash',
        command: () => {
          delete_blog()
        }
    }
  ]
  
  const get_configs = async () => {
    blogoptions.value.bUrls = [{ bUrl: "首页" }, { bUrl: "段落" },{ bUrl: "笔记" }, { bUrl: "日记" }, { bUrl: "模版" }]
    blogoptions.value.bContents = await useSlade.get_conts(blog.value);
  }
  
  const get_bsconts = async () => {
    let postgroup = useCommon.o_o(blog.value)
    beforepost(postgroup);
    blogoptions.value.bsContents = await useSlade.get_bsconts(postgroup);
  }
  const get_names = async () => {
    let postgroup = useCommon.o_o(blog.value)
    beforepost(postgroup);
    blogoptions.value.bNames = await useSlade.get_names(postgroup);
  }
  
  const beforepost = (obj) => {
    if (obj.bContent)
      obj.bContent = useCommon.o_s(obj.bContent, "bContent")
    if (obj.bsContent)
      obj.bsContent = useCommon.o_s(obj.bsContent, "bsContent")
    if (obj.bUrl)
      obj.bUrl = useCommon.o_s(obj.bUrl, "bUrl")
    if (obj.bName)
      obj.bName = useCommon.o_s(obj.bName, "bName")
  }
  
  const create_blog = async () => {
    blog.value.bOrderSN=menu_block.value.pId
    
    console.log(blog.value)
    if (blog.value.bContent && blog.value.bsContent && blog.value.bName && blog.value.bKeyWords && blog.value.bDescription) {
      let postgroup = useCommon.o_o(blog.value)
      beforepost(postgroup);
      await useSlade.add_blog(postgroup, sendshare.value);
      search_blog();
      
    }
    else
      useAlert.salert("信息不得为空")
  }
  
  const update_blog = async () => {
    blog.value.bOrderSN=menu_block.value.pId
    menu_block.value.pPlan=blog.value.bName
    menu_block.value.pStart=new Date()
    menu_block.value.pEnd=new Date()
    menu_block.value.pCost=0
    if (blog.value.bContent && blog.value.bsContent && blog.value.bName && blog.value.bKeyWords && blog.value.bDescription) {
      console.log(blog.value)
      let postgroup = useCommon.o_o(blog.value)
      beforepost(postgroup);
      console.log(postgroup.value)
      await useSlade.upd_blog(postgroup, sendshare.value);
      
      search_blog();
      
    }
    else
      useAlert.salert("信息不得为空")
  }
  let menu_block=ref({})

  const bind_nblog = async () => {
    console.log(menu_block.value.pId)
    if (menu_block.value.pId>0) {
      await useSlade.bind_nblog(menu_block.value);
    }
    else
      useAlert.salert("信息不得为空")
  }
  
  const delete_blog = async () => {
    let postgroup = useCommon.o_o(blog.value)
    beforepost(postgroup);
    await useSlade.del_blog(postgroup, sendshare);
    search_blog();
  }
  
  let isnewblog = ref(true)
  let oclass = ref('height_100')
  const props = defineProps({
    blog_id: String,
    menu_id: Number,
    bname:String
  })
  
  
  const search_blog = async () => {
    let postgroup = useCommon.o_o(blog.value)
    beforepost(postgroup);
    let getresult = await useSlade.get_blog(postgroup);
    if (getresult && getresult.bName) {
      getshare.value = getresult
      sendshare.value =getresult
      blog.value = getresult
      isnewblog.value = false;
      blog.value.bUrl = useCommon.ss_o(getshare.value.bUrl, "bUrl")
      menu_block.value.pFiles=blog.value.Id
      
    }
    else {
      getresult = await useSlade.get_blogid(blog);
  
      if (getresult && getresult.NID)
        blog.value.NID = getresult.NID
      isnewblog.value = true;
      menu_block.value.pFiles=blog.value.NID
    }
    if(blog.value.Id &&blog.value.NID&& blog.value.NID != blog.value.Id){
      useApi.add_menus(my_bts[3])
    }
    else{
      useApi.remove_menus(my_bts[3])
    }
    if(isnewblog.value){
        useApi.add_menus(my_bts[0])
        useApi.remove_menus(my_bts[1])
        useApi.remove_menus(my_bts[4])
      }
      else{
        useApi.remove_menus(my_bts[0])
        useApi.add_menus(my_bts[1])
        useApi.add_menus(my_bts[4])
      }
    
  }

  const search_blog_info = async () => {    
    menu_block.value.pId=props.menu_id;
    menu_block.value.pFiles=blog.value.Id
    blog.value.bName = props.bname 
    blog.value.bOrderSN=props.menu_id
    let labels=await useSlade.get_nbloglable(menu_block.value)
    if (labels && labels.CT1) {
      blog.value.bContent = labels.CT1
      blog.value.bsContent = labels.CT2
    }
  }
  onBeforeUnmount(() => {
      useApi.remove_menus(my_bts[0])
      useApi.remove_menus(my_bts[1])
      useApi.remove_menus(my_bts[2])
      useApi.remove_menus(my_bts[3])
      useApi.remove_menus(my_bts[4])
  });
  onMounted(() => {
    useApi.add_menus(my_bts[2])
    get_configs();
    search_blog_info()
    if (props.blog_id && props.blog_id.length > 0) {
      oclass.value = 'height_35'
      blog.value.Id = props.blog_id.replace('blog_', '');
      
      search_blog();   
       
    }
    else {
      oclass.value = 'height_100'
    }
    
    
    document.title = '[写笔记]-[碳硅锗兮]';
    document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
    document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
  })
  
  const openclass = () => {
    if (oclass.value == 'height_35')
      oclass.value = 'height_100'
    else
      oclass.value = 'height_35'
  }
  
  const customBase64Uploader = async (event) => {
    const file = event.files[0];
    let formData = new FormData()
    formData.append('files', file)
    useApi.uploadfile('TinyMCE/Upload', formData).then(res => {
      blog.value.bImgUrl = 'url(' + useApi.myapiurl + res.data.location + ')';
      //blog.value.bImgUrl=blog.value.bImgUrl.replace('//','/')
    })
  }
  </script>
  
  <style>
  /* Styles required only for the example above */
  .scrollspy-example-collapsible {
    position: relative;
    height: 200px;
    overflow: auto;
  }
  
  .input_box {
    padding: 0.5em 2em 0.5em 2em;
    border-radius: 1em 1em;
    border: 1px silver solid;
    width: 98%;
    margin: 0.5em;
  }
  
  .height_35 {
    height: 0em;
  }
  
  .height_100 {
    height: auto;
  }
  
  </style>
  