<template>
<div style="padding: 1em;border: 1px solid #e6e6e6;border-radius: 0.5em;">
    <div class="div_66_100 align_lleft_scenter">
        <a :href="'/datasheet/show/' + myobject.Id" @click="isShow=true;showid=myobject.Id"><i class="pi pi-tag"></i> {{ myobject.Name }}</a>
    </div>
    <div class="div_33_50 align_lleft_scenter"><i class="pi pi-dollar"></i>{{ myobject.Price }}</div>
    <div class="div_33_50 align_lleft_scenter"><i class="pi pi-globe"></i>{{
        useCommon.jsons_o(myobject.Manufacture).vName }}</div>
    <div class="div_33_50 align_lleft_scenter"><i class="pi pi-bookmark"></i>{{
        useCommon.jsons_o(myobject.Style1).vStyle1 }}</div>
    <div class="div_33_50 align_lleft_scenter"><i class="pi pi-bookmark"></i>{{
        useCommon.jsons_o(myobject.Style2).vStyle2 }}</div> 
    <div class="clear"></div>                       
    <div v-if="myobject.Description&&myobject.Description.length>0" class="div_100 align_lleft_scenter" style="height: 5em; overflow:scroll;word-wrap: break-word;" :title="myobject.Description">
        <i class="pi pi-home"></i> {{ myobject.Description }}                           
    </div>
    <div v-else class="div_100 align_lleft_scenter" style="height: 1.5em; overflow: hidden;">                           
        <i class="pi pi-home"></i> {{ myobject.FileName }}
    </div>

    <div class="clear">
       
    </div>
    <slot name="footer">

    </slot>
</div>
</template>
<script setup>
import { ref, onMounted, defineProps,watchEffect } from "vue";
import useCommon from '@/services/useCommon'
const props = defineProps({
    in_object: Object
})
let myobject=ref(Object)
onMounted(() => {
    myobject.value=props.in_object
})

watchEffect(() => {
    myobject.value=props.in_object
})

</script>