
<template>
    <div>
      <div id="scrollspy-collapsible">
        <div class="card">
          <div>
            <div style="margin: 1em;padding: 1em;border-radius: 1em 1em;text-align: center;">
              <div class="margin_top div_50_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="myobject.Project" :options="myother.Projects" optionLabel="pName" placeholder="项目名称"
                       class="w-full md:w-14rem" />
                    <label for="companyname">项目名称</label>
                  </span>
                </div>
              </div>

              <div class="margin_top div_50_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="myobject.Name" />
                    <label for="companyname">器件名字</label>
  
                  </span>
                </div>
              </div>
  
              <div class="margin_top div_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="myobject.FileName" @change="search_blog(myobject)" />
                    <label for="companyname">规格书名称[上传后显示]</label>
                    <Button icon="pi pi-search" title="规格书名称" @click="search_blog(myobject)" />
                  </span>
                </div>
              </div>
  
              
              <div class="margin_top div_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="myobject.Description" />
                    <label for="companyname">器件描述</label>
  
                  </span>
                </div>
              </div>
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="myobject.Style1" :options="myother.Style1s" optionLabel="vStyle1" placeholder="大类型"
                      @change="get_styles_ss(myobject.Style1)" class="w-full md:w-14rem" />
                    <label for="companyname">大类型</label>
  
                  </span>
                </div>
              </div>
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="myobject.Style2" :options="myother.Style2s" optionLabel="vStyle2" placeholder="小类型"
                      @change="get_styles_v(myobject.Style2)" class="w-full md:w-14rem" />
                    <label for="companyname">小类型</label>
  
                  </span>
                </div>
              </div>
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="myobject.Manufacture" :options="myother.Manufactures" optionLabel="vName"
                      placeholder="品牌" class="w-full md:w-14rem" />
                    <label for="companyname">品牌</label>
  
                  </span>
                </div>
              </div>
              <div class="margin_top div_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="myobject.Price" type="number" />
                    <label for="companyname">参考价格($)</label>
                  </span>
                </div>
              </div>
  
              <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue" :sharedata="getshare"></ObjectShare>
              <div class="clear"></div>
              <div class="place1em"></div>
              <div>
                <Button icon="pi pi-refresh" v-if="myobject.Id > 0" class="margin" title="更新资料" label="" style="width: 2em;height: 2em;border-radius: 50% 50%;"
                  @click="update_blog()" />
                
              </div>
              
              <div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 1em;"></div>
      </div>
  
    </div>
  </template>
      
  <script setup>
  import ObjectShare from '@/components/ObjectShare.vue'
  import { ref, onMounted, defineEmits,defineProps } from "vue";
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  import Dropdown from 'primevue/dropdown';
  import useCommon from '@/services/useCommon';
  import useSlade from "@/services/useSlade"
  import useAlert from '@/services/useAlert';
  
  
  const props = defineProps({
      in_object: Object
  })
  
  let myobject = ref({});
  let myother = ref({})
  let sendshare = ref({});
  let getshare = ref({});
  sendshare.value.Id = -1
  const getrolevalue = (obj) => {
    sendshare.value = obj
  }
  const get_configs = async () => {
    let oprojects=await useSlade.getcs_projects()
    myother.value.Projects = useCommon.removeKey(oprojects,'canEdit');

    myother.value.Style1s = await useSlade.get_styles_s();
    console.log(myother.value)
  }
  
  const get_styles_ss = async (obj) => {
    myother.value.Style2s = await useSlade.get_styles_ss(obj)
  }
  
  const get_styles_v = async (obj) => {
    myother.value.Manufactures = await useSlade.get_styles_v(obj)
  }
  
  const beforepost = (obj) => {
    if (obj.Project)
      obj.Project = useCommon.jsono_s(obj.Project)
    if (obj.Style1)
      obj.Style1 = useCommon.jsono_s(obj.Style1)
    if (obj.Style2)
      obj.Style2 = useCommon.jsono_s(obj.Style2)
    if (obj.Manufacture)
      obj.Manufacture = useCommon.jsono_s(obj.Manufacture)
  }
  const update_blog = async () => {
    if (myobject.value.Project && myobject.value.FileName && myobject.value.Name && myobject.value.Description && myobject.value.Style1 && myobject.value.Style2 && myobject.value.Manufacture) {
      let postgroup = useCommon.o_o(myobject.value)
      beforepost(postgroup);
      await useSlade.upd_ds(postgroup, sendshare.value);
      search_blog();
      ondatachange()
    }
    else
      useAlert.salert("信息不完整")
  }
  const search_blog = async () => {
    await get_configs();
    let postgroup = useCommon.o_o(myobject.value)
    beforepost(postgroup);
    if(postgroup.Id > 0)
    {
      let getresult = await useSlade.get_ds(postgroup);
      if (getresult && getresult.FileName) 
      {
        getshare.value = getresult
        sendshare.value=getresult
        myobject.value = getresult
        myobject.value.Project = useCommon.jsons_o(myobject.value.Project)
        myobject.value.Style1 = useCommon.jsons_o(myobject.value.Style1)
        get_styles_ss(myobject.value.Style1)
        myobject.value.Style2 = useCommon.jsons_o(myobject.value.Style2)
        get_styles_v(myobject.value.Style2)
        myobject.value.Manufacture = useCommon.jsons_o(myobject.value.Manufacture)
      }
      else {
        myobject.value.Id = -1;
      }
    }
    else 
    {
      myobject.value.Id = -1;
    }
  }
  
  onMounted(() => {
    
    myobject.value=props.in_object
    search_blog()
  })
  const emits = defineEmits(['ondatachange']);
  function ondatachange() {
    emits("ondatachange")
  }
  </script>
    
      