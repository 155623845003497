
<template>
  <div>
    <div style="height: 12em;background-size:cover;background-image:url(images/f4.jpg)">

    </div>
    <!-- Scrollspy -->
    <div id="scrollspy-collapsible">
      <div class="card">
        <div>
          <div style="margin: 1em;padding: 1em;border-radius: 1em 1em;">
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <Dropdown v-model="myobject.Project" :options="myother.Projects" optionLabel="pName" placeholder="项目名称"
                    @change="get_list" class="w-full md:w-14rem" />
                  <label for="companyname">项目名称</label>
                </span>
              </div>
            </div>

            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.FileName" @change="search_ds(myobject)" />
                  <label for="companyname">规格书名称</label>
                  <Button icon="pi pi-search" title="规格书名称" @click="search_ds(myobject)" />
                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.Price" type="number" />
                  <label for="companyname">参考价格($)</label>
                </span>
              </div>
            </div>


            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <Dropdown v-model="myobject.Style1" disabled :options="myother.Style1s" optionLabel="vStyle1"
                    placeholder="大类型" @change="get_styles_ss(myobject.Style1)" class="w-full md:w-14rem" />
                  <label for="companyname">大类型</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <Dropdown v-model="myobject.Style2" disabled :options="myother.Style2s" optionLabel="vStyle2"
                    placeholder="小类型" @change="get_styles_v(myobject.Style2)" class="w-full md:w-14rem" />
                  <label for="companyname">小类型</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <Dropdown v-model="myobject.Manufacture" disabled :options="myother.Manufactures" optionLabel="vName"
                    placeholder="品牌" class="w-full md:w-14rem" />
                  <label for="companyname">品牌</label>

                </span>
              </div>
            </div>

            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.Name" />
                  <label for="companyname">PartNumber</label>
                  <Button icon="pi pi-search" title="规格书名称" @click="search_blog(myobject)" />
                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.Code" />
                  <label for="companyname">编码</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.MainCode" />
                  <label for="companyname">主料编码</label>
                </span>
              </div>
            </div>
            <div class="margin_top div_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.Description" />
                  <label for="companyname">器件描述</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.ONames" />
                  <label for="companyname">其他属性名称(;隔开)</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.OValues" />
                  <label for="companyname">其他属性值(;隔开)</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.EDAType" />
                  <label for="companyname">EDA属性</label>
                  <Button icon="pi pi-search" title="规格书名称" @click="search_eda()" />
                </span>
              </div>
            </div>
            <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue" :sharedata="getshare"></ObjectShare>
            <div class="clear"></div>
            <div class="place1em"></div>
            <div>
              <Button icon="pi pi-plus" v-if="myobject.Id <= 0" class="margin" title="上传资料" label="新增"
                @click="create_blog()" />
              <Button icon="pi pi-refresh" v-if="myobject.Id > 0" class="margin" title="更新资料" label="更新"
                @click="update_blog()" />
              <Button icon="pi pi-times" v-if="myobject.Id > 0" class="margin" title="删除资料" label="删除"
                @click="delete_blog()" />
              <Button icon="pi pi-refresh" class="margin" v-if="myobject.FullPath && myobject.FullPath.endsWith('.pdf')"
                title="预览规格书仅限pDF" label="预览" @click="isdisplay = ~isdisplay" />

            </div>
            <div class="place1em"></div>
            <PdfReader :pdfid="myobject.Id" v-if="isdisplay"></PdfReader>
            <div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 1em;"></div>
    </div>

  </div>
</template>
    
<script setup>
import ObjectShare from '@/components/ObjectShare.vue'
import { ref, onMounted, defineEmits } from "vue";
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import useCommon from '@/services/useCommon';
import useSlade from "@/services/useSlade"
import PdfReader from './PdfReader.vue';
import useAlert from '@/services/useAlert';
let myobject = ref({});
let myother = ref({})
let sendshare = ref({});
let getshare = ref({});
sendshare.value.Id = -1
const getrolevalue = (obj) => {
  sendshare.value = obj
}
const get_configs = async () => {
  myother.value.Projects = await useSlade.getcs_projects();
  myother.value.Style1s = await useSlade.get_styles_s();
}

const get_styles_ss = async (obj) => {
  myother.value.Style2s = await useSlade.get_styles_ss(obj)
}

const get_styles_v = async (obj) => {
  myother.value.Manufactures = await useSlade.get_styles_v(obj)
}

onMounted(() => {
  get_configs();
})

let isdisplay = ref(false);

const beforepost = (obj) => {
  if (obj.Project)
    obj.Project = useCommon.jsono_s(obj.Project)
  if (obj.Style1)
    obj.Style1 = useCommon.jsono_s(obj.Style1)
  if (obj.Style2)
    obj.Style2 = useCommon.jsono_s(obj.Style2)
  if (obj.Manufacture)
    obj.Manufacture = useCommon.jsono_s(obj.Manufacture)
}

const create_blog = async () => {
  if (myobject.value.Name && myobject.value.Code && myobject.value.MainCode && myobject.value.Description && myobject.value.Project) {
    let postgroup = useCommon.o_o(myobject.value)
    beforepost(postgroup);
    await useSlade.add_device(postgroup, sendshare.value);
    search_blog();
    ondatachange()
  }
  else
    useAlert.salert("信息不完整")
}

const update_blog = async () => {
  if (myobject.value.Name && myobject.value.Code && myobject.value.MainCode && myobject.value.Description && myobject.value.Project) {
    let postgroup = useCommon.o_o(myobject.value)
    beforepost(postgroup);
    await useSlade.upd_device(postgroup, sendshare.value);
    search_blog();
    ondatachange()
  }
  else
    useAlert.salert("信息不完整")
}

const delete_blog = async () => {
  let postgroup = useCommon.o_o(myobject.value)
  beforepost(postgroup);
  await useSlade.del_device(postgroup, sendshare);
  search_blog();
  ondatachange()
}

const search_blog = async () => {
  let postgroup = useCommon.o_o(myobject.value)
  beforepost(postgroup);
  let getresult = await useSlade.get_device(postgroup);
  if (getresult && getresult.FileName) {
    getshare.value = getresult
    myobject.value = getresult
    myobject.value.Project = useCommon.jsons_o(myobject.value.Project)
    myobject.value.Style1 = useCommon.jsons_o(myobject.value.Style1)
    get_styles_ss(myobject.value.Style1)
    myobject.value.Style2 = useCommon.jsons_o(myobject.value.Style2)
    get_styles_v(myobject.value.Style2)
    myobject.value.Manufacture = useCommon.jsons_o(myobject.value.Manufacture)
  }
  else {
    myobject.value.Id = -1;
  }

  ondatachange()
}

const search_eda = async () => {
  let postds = { Name: myobject.value.EDAType, SCH: myobject.value.EDAType, PCB: myobject.value.EDAType, D3D: myobject.value.EDAType }
  let getresult = await useSlade.get_eda(postds);
  if (getresult && getresult.FileName) {
    getshare.value = getresult
    myobject.value.EDAType = getresult.Name
    myobject.value.Id = -1;
  }
}

const search_ds = async () => {
  let postds = { FileName: myobject.value.FileName }
  let getresult = await useSlade.get_ds(postds);
  if (getresult && getresult.FileName) {
    getshare.value = getresult
    myobject.value = getresult
    myobject.value.Project = useCommon.jsons_o(myobject.value.Project)
    myobject.value.Style1 = useCommon.jsons_o(myobject.value.Style1)
    get_styles_ss(myobject.value.Style1)
    myobject.value.Style2 = useCommon.jsons_o(myobject.value.Style2)
    get_styles_v(myobject.value.Style2)
    myobject.value.Manufacture = useCommon.jsons_o(myobject.value.Manufacture)
    myobject.value.Id = -1;
  }
}



const emits = defineEmits(['ondatachange']);
function ondatachange() {
  emits("ondatachange")
}
</script>
  
    