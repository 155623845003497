<template>
  <div class="about">
    <div v-for="{ID,SN} in duts.data" :key="ID">
      11
      {{ SN }}
    </div>
  </div>
</template>

<script>
  import {getCurrentInstance ,ref} from 'vue';
  
  export default{
    components: {

    },
    data(){
      return {
        proxy:getCurrentInstance(),
        $untils:this.proxy,
        UserAccount:this.belongName,
        current_user:this.$store.state.username,
        my_name:this.$store.state.realname,
        project_name:'',
        duts:[]
        
      }
    }, 
    props:['dut_id'],   
    mounted(){
      console.log()
      this.get_duts();
      
    },
    watch:{

    },
    methods:{
      get_duts:function(){ 
        this.$untils.$getapi("api/nologin/getduts/download").then(res=>{
          if(res.data)
          {            
            this.duts=ref(res.data);
            console.log(this.duts);
          }              
        })
    },

    },



  }

</script>


