
<template id="blog_show">
  <div v-if="getobject">
    <Panel header="资料信息">
      <div>
        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="pName" v-model="getobject.fDisplayName" disabled />
              <label for="pName">资料名称</label>
            </span>
          </div>
        </div>

        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="pName" v-model="getobject.fManufacture" disabled />

              <label for="pName">资料归属</label>
            </span>
          </div>
        </div>

        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="pName" v-model="getobject.fLabel" disabled />

              <label for="pName">资料标签</label>
            </span>
          </div>
        </div>



        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="pName" v-model="getobject.Auther" disabled />

              <label for="pName">上传者</label>
            </span>
          </div>
        </div>

        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="pName" v-model="getobject.fDownloadTimes" disabled />

              <label for="pName">下载次数</label>
            </span>
          </div>
        </div>

        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="pName" v-model="getobject.fViewTimes" disabled />

              <label for="pName">查看次数</label>
            </span>
          </div>
        </div>

        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="pName" v-model="getobject.fDloadName" disabled />
              <label for="pName">资料名称</label>
            </span>
          </div>
        </div>

        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <Calendar id="uGdday" v-model="getobject.CreateTime" disabled />
              <label for="uGdday">分享时间</label>
            </span>
          </div>
        </div>

        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="pName" v-model="getobject.fLocalUrl" disabled />

              <label for="pName">本地下载链接</label>
            </span>
          </div>
        </div>

        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="pName" v-model="getobject.fOtherUrl" disabled />

              <label for="pName">网盘链接</label>
            </span>
          </div>
        </div>

        <div class="div_33_100 margin_top">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="pName" v-model="getobject.fPassWord" disabled />

              <label for="pName">网盘密码</label>
            </span>
          </div>
        </div>

        <div style="clear:both;"></div>
        <div class="place1em"></div>
      </div>
      <div>


      </div>

    </Panel>
    <div class="place1em"></div>
    <Panel header="资料描述">
      <div :innerHTML="getobject.fDescription"
        style="text-align: left;line-height: 2em;font-family: Arial, Helvetica, sans-serif;" class="line-numbers"></div>

        <div :innerHTML="getobject.fText"
        style="text-align: left;line-height: 2em;font-family: Arial, Helvetica, sans-serif;" class="line-numbers"></div>

    </Panel>
    <div class="place1em"></div>
    <Panel header="下载链接">
      <div class="margin_top" v-if="getobject.fDloadName">
        <a @click="useApi.downloadfetch('api/File/' + getobject.fId, getobject.fDloadName)">
          <Button icon="pi pi-download" :title="' 下载' + getobject.fId" label="本站下载"
            style="cursor: pointer;font-size: 1em !important;" />
        </a>
      </div>
      <div class="margin_top" v-if="getobject.fOtherUrl">
        <a :href="getobject.fOtherUrl" target="_blank">
          <i class="pi pi-download nicon_btn" :title="' 下载' + getobject.fId"
            style="cursor: pointer;font-size: 1em !important;">网盘下载</i>
        </a>
      </div>
    </Panel>
    <div class="place1em"></div>
    <Panel header="资料评论">
      <div v-if="authed">
        <CommonPlan :isparent="-1" pstyle="Msg" pheader="评论资料" :setroledata="setroledata"></CommonPlan>
      </div>
      <div class="padding" v-else>
        登录后查看评论
      </div>
    </Panel>
  </div>
</template>
<script setup>
import { ref, defineProps, onMounted,defineEmits, onBeforeUnmount} from "vue";
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Panel from 'primevue/panel';
import useApi from "@/services/useApi";
import CommonPlan from '@/components/PlanMsg.vue';
import useSlade from "@/services/useSlade";
import "prismjs/themes/prism-tomorrow.min.css"//引入代码高亮主题（这个去node_modules的安装prismjs中找到想使用的主题即可）
import "prismjs/plugins/line-numbers/prism-line-numbers.min.js"
import "prismjs/plugins/line-numbers/prism-line-numbers.min.css"//行号插件的样式
import Prism from "prismjs"//导入代码高亮插件的core（里面提供了其他官方插件及代码高亮样式主题，你只需要引入即可）
import { useStore } from "vuex";
import useFree from "@/services/useFree";
const props = defineProps({
  blog_id: String
})
const emits = defineEmits(['add-button','remove-button']);
const my_bts = [
      {
          label: '返回资源首页',
          icon: 'pi pi-home',
          command: () => {
            useApi.jumpto('/fileshare')
          }
      },
      {
          label: '编辑这个资源',
          icon: 'pi pi-pencil',
          command: () => {
            useApi.jumpto('/fileshare/' + getobject.value.fId)
          }
      }
    ]
onBeforeUnmount(() => {
  emits('remove-button', my_bts);
});
onMounted(() => {
  if (props.blog_id && props.blog_id.length > 0) {
    search_blog();
  }
  emits('add-button', my_bts[0]);
})
const store = useStore();
let authed = ref(store.state.authed);
let getobject = ref({})
let setroledata = ref({})
const search_blog = async () => {
  getobject.value.fId = props.blog_id
  let result = {}
  if (authed.value)
    result = await useSlade.get_file(getobject.value)
  else
    result = await useFree.get_file(getobject.value.fId)
  if (result && result.fDisplayName) {
    getobject.value = result
    setTimeout(function () {
      Prism.highlightAll();
    }, 1000);
    document.title = '[' + result.fDisplayName + ']-[资源分享]-[碳硅锗兮]';
    document.querySelector('meta[name="keywords"]').setAttribute('content', result.fLabel)
    document.querySelector('meta[name="description"]').setAttribute('content', result.fDescription)
  }
  setroledata.value = { rdRange: { rdRange: '所有人可见' }, wrRange: { wrRange: '仅自己编辑' }, sShareGroup: "{\"bId\":" + getobject.value.fId + ",\"bName\":\"" + getobject.value.fDisplayName + "\"}" }
  if(authed.value && getobject.value.canEdit)
  {
    emits('add-button', my_bts);
  }
}

</script>

<style>
.label_left {
  width: 4em;
  float: left;
  padding-top: 0.5em;
}

.label_right {
  float: left;
  max-width: 78%;
}
</style>

