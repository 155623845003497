import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';



import 'mdb-vue-ui-kit/css/mdb.min.css';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';

import baseapi from '@/services/useApi'
import common from './services/useCommon';



const app=createApp(App);
app.provide('baseToKen','xxx')
app.provide('isLogin',false)
app.provide('UserAccount','xxx')




app.use(PrimeVue, { green: true });
app.use(ToastService);
app.use(router)

app.use(store)

app.config.globalProperties.baseapi = baseapi
app.config.globalProperties.common = common

app.mount('#app')








