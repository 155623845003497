<template>  
    <Dialog v-model:visible="is_upload" header="上传规格书" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"> 
        
    </Dialog>
    <div>
             
        <TabView>
            <TabPanel header="我的股票"> 
                <StockBuyPanel></StockBuyPanel>                  
            </TabPanel>
            <TabPanel header="股票习惯"> 
                <div class="card" style="padding: 1em;text-align: left;">
                    <h4>交易策略</h4>
                    <h5>1. 每日09:30-10:30点之间官网,确定售卖价格。</h5>
                    <h5>2. 每日10:30-11:30点之间挂6%委托卖出</h5>
                    <h5>3. 每日13:00-13:30点之间手动高价(3%)卖出。</h5>
                    <h5>4. 每日13:30-14:30点之间挂低价(-3%)买入。</h5>
                    <h5>5. 每日14:30-15:00点之间手动低价买入。</h5>
                    <h5>6. 当天若亏损,就不进行交易。</h5>
                </div>                   
            </TabPanel>
            
            <TabPanel header="推荐股票">
                <StockPanel :mydata="heima_stocks"></StockPanel> 
            </TabPanel> 
            <TabPanel header="制定策略">
                <StockPanelBuySet></StockPanelBuySet> 
            </TabPanel>             
            <TabPanel header="股票回购">
                <StockPanel :mydata="poban_stocks"></StockPanel> 
            </TabPanel> 
            
        </TabView>        
    </div>    
</template>
    
<script setup>
import { ref, onMounted,defineEmits,onBeforeUnmount } from "vue";
import useSlade from '@/services/useSlade';
import StockPanel from "@/components/stock/StockPanel.vue";
import StockBuyPanel from "@/components/stock/StockPanelBuy.vue";
import StockPanelBuySet from "@/components/stock/StockPanelBuySet.vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dialog from "primevue/dialog";
import { useRoute } from 'vue-router';
import useAlert from "@/services/useAlert";

let heima_stocks = ref([])
let xinjin_stocks = ref([])
let yzt_stocks = ref([])
let poban_stocks = ref([])
const listshow = async () => {
    xinjin_stocks.value = await useSlade.get_xinjin()
    heima_stocks.value = await useSlade.get_heima()
    yzt_stocks.value = await useSlade.get_steady()
    poban_stocks.value = await useSlade.get_poban()
}

const is_upload=ref(false)
const is_autoupd=ref(false)
const refresh_time = () => {
    if(is_autoupd.value){
    listshow();
    const now = new Date();
    const currentHour = now.getHours();
    const dayOfWeek = now.getDay();
    if((currentHour <= 15 && currentHour >= 9)&&(dayOfWeek<6)){
        setTimeout(() => {
        refresh_time();
        }, 5000);
    }
    }
}
const my_bt1 = {
          label: '自动运行',
          icon: 'pi pi-car',
          command: () => {
            is_autoupd.value = !is_autoupd.value
            if(is_autoupd.value)
                useAlert.salert("已开启自动监听...")
            else
            useAlert.salert("已关闭自动监听...")
          }
      }
const route = useRoute();
const emits = defineEmits(['add-button','remove-button']);
onBeforeUnmount(() => {
emits('remove-button', my_bt1);
//
});
onMounted(() => {
    emits('add-button', my_bt1);
    //import { useRoute } from 'vue-router';
    //const route = useRoute();
    useSlade.inject_route(route)
    refresh_time();
    document.title = '[股票测试]-[碳硅锗兮]';
})
</script>
    