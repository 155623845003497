/*该控件为通用的树形显示控件,可以进行数据展示,数据更新，数据删除。*/
<template>
    <div v-if="props.pstyle == 'MyFinished'">
        <span class="p-float-label margin_top">
            <Calendar id="planstart" date-format="yy/mm/dd" style="width: 100%;" :manualInput="true" showButtonBar
                placeholder="选择时间" v-model="sel_day.pStart" v-on:update:model-value="getoneday(sel_day)" />
            <label for="planstart" style="color: white;">选择时间</label>
        </span>
        <div class="place1em"></div>
        
    </div>

    <div v-for="{ index } in mydata" :key="index" :class="'box box' + ((index % 6) + 1)">
        <div style="text-align:left" v-if="(mydata[index]) && !(!mydata[index].isEdit && (!mydata[index].isChange))">
            <div
                style="font-size: 1em;margin-top: 1em;font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;">
                <div class="margin">
                    <span class="p-float-label margin_top div_100">
                        <InputText id="plan" placeholder="目标" maxlength="30" style="width: 100%;"
                            v-model="mydata[index].pPlan" />
                        <label for="plan" style="color: white;">事务描述</label>
                    </span>

                </div>
                <div class="clear"></div>
                <div class="margin">
                    <div
                        v-if="(props.pstyle == 'MyFairs' || props.pstyle == 'MyQuestion' || props.pstyle == 'GroupGoal' || props.pstyle == 'ProjectPlan') && props.pstyle != 'Msg'">
                        <span class="p-float-label margin_top div_33_100">
                            <InputText id="cost" placeholder="耗时(天)" type="number" style="width: 100%;" maxlength="30"
                                v-model="mydata[index].pCost" @change="cal_start(mydata, mydata[index].index)" />
                            <label for="cost" style="color: white;">事务耗时({{ mydata[index].pCost * 8 }}小时)</label>
                        </span>

                        <span class="p-float-label margin_top div_34_100">
                            <Calendar id="planstart" date-format="yy/mm/dd" style="width: 100%;" :manualInput="true"
                                showButtonBar placeholder="目标开始时间" v-model="mydata[index].pStart"
                                v-on:update:model-value="cal_start(mydata, mydata[index].index)" />
                            <label for="planstart" style="color: white;">目标开始时间</label>
                        </span>
                        <span class="p-float-label margin_top div_33_100">
                            <Calendar id="planend" date-format="yy/mm/dd" style="width: 100%;" showButtonBar
                                placeholder="目标结束时间" v-model="mydata[index].pEnd"
                                v-on:update:model-value="cal_end(mydata, mydata[index].index)" />
                            <label for="planend" style="color: white;">目标结束时间</label>
                        </span>
                    </div>
                    <div v-if="props.pstyle == 'GroupMap'">
                        <div style="padding: 1.5em">
                            <Slider v-model="mydata[index].pCost" />
                        </div>
                    </div>
                </div>
                <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue(index, $event)" :sharedata="mydata[index]"
                    :setroledata="props.setroledata" :disabled="mydata[index].roledisable || (props.pstyle == 'GroupGoal' || props.pstyle == 'GroupMap'
                        || props.pstyle == 'Msg')" labelstyle="white"></ObjectShare>

                <div class="margin_top div_100"
                    v-if="(props.pstyle != 'GroupGoal' && props.pstyle != 'GroupMap') && props.pstyle != 'Msg'">
                    <div class="p-inputgroup flex-1 ">
                        <span class="p-float-label">
                            <Dropdown v-model="mydata[index].pProjectInfo" :options="mydata[index].pProjectInfos"
                                :disabled="mydata[index].roledisable" optionLabel="pName" placeholder="所属项目"
                                class="w-full md:w-14rem" />
                            <label style="color: white;" for="uGangwei">所属项目</label>
                        </span>
                    </div>
                </div>
                <div class="clear"></div>
                <div style="text-align: center;padding-top: 0.8em;">
                    <i class="icon_btn pi pi-times" @click="del_childplan(mydata[index].index, mydata, mydata[index].pId);"
                        :title="'删除事项' + index" v-if="mydata[index].isEdit"></i>
                    <i class="pi pi-check" style="cursor: pointer;margin: 0.5em;"
                        v-bind:title="'父项目ID为' + mydata[index].pParentId" @click="add_plan(mydata[index])"
                        v-if="mydata[index].isEdit && mydata[index].pPlan.length > 0"></i>
                    <i class="pi pi-lock" style="cursor: pointer;margin: 0.5em;" v-bind:title="mydata[index].index"
                        @click="mydata[index].isChange = true"
                        v-if="!mydata[index].isEdit && (!mydata[index].isChange)"></i>
                    <i class="pi pi-lock-open" style="cursor: pointer;margin: 0.5em;" v-bind:title="mydata[index].pId"
                        @click="upd_plan(mydata[index])" v-if="mydata[index].isChange"></i>
                </div>
            </div>
        </div>

        <div style="text-align:left;position: relative;" v-else>
            <div style="font-size: 1em;margin-top: 1em;min-height: 3em;" v-if="(mydata[index])">
                <div style="font-weight: bold;color:rgb(244, 245, 244);font-size:1.1em">
                    {{ mydata[index].pPlan }}({{ mydata[index].pStatus }})
                </div>
                <div class="div_50_100" v-if="props.pstyle == 'ProjectQuestion' || props.pstyle == 'ProjectPlan'">

                    <div class="card_header" style="width: 100%;text-align: left;" :title="mydata[index].group_name">
                        <i class="pi pi-chevron-circle-left card_key" @click="getaffairslayer(mydata[index],mydata[index].pId);;mydata[index].isChildShow = true;"
                            title="展开子项目"
                            v-if="(!mydata[index].isChildShow) && mydata[index].childrenlen > 0"></i>
                        <i class="pi pi-chevron-circle-down card_key" @click="mydata[index].isChildShow = false"
                            title="合拢子项目"
                            v-if="mydata[index].isChildShow && mydata[index].childrenlen > 0"></i>

                        <a style="color: white;" :href="'/project/' + (mydata[index].pname)"><i class="pi pi-wallet"></i>
                            {{ mydata[index].pProjectInfo.pName }}</a>
                    </div>
                </div>
                <div class="div_50_100" v-else>

                    <div class="card_header" style="width: 50%;text-align: left;" :title="mydata[index].group_name">
                        <i class="pi pi-chevron-circle-left card_key" @click="getaffairslayer(mydata[index],mydata[index].pId);mydata[index].isChildShow = true;"
                            title="展开子项目"
                            v-if="(!mydata[index].isChildShow) && mydata[index].childrenlen > 0"></i>
                        <i class="pi pi-chevron-circle-down card_key" @click="mydata[index].isChildShow = false"
                            title="合拢子项目"
                            v-if="mydata[index].isChildShow && mydata[index].childrenlen > 0"></i>

                        <a style="color: white;" :href="'/project/' + (mydata[index].pname)"><i class="pi pi-wallet"></i>
                            {{ mydata[index].pBelongInfo.uGroupName }}</a>
                    </div>
                    <div class="card_header" style="width: 50%;">
                        <i class="pi pi-user"></i> {{ mydata[index].pBelongInfo.uCompanyName }}
                    </div>
                </div>
                <div class="div_50_100">
                    <div
                        v-if="props.pstyle == 'MyFairs' || props.pstyle == 'MyQuestion' || props.pstyle == 'GroupGoal' || props.pstyle == 'ProjectPlan'">
                        <div class="card_header" style="width: 35%;" title="起始时间"><i class="pi pi-calendar-minus"></i>
                            {{ fdate(mydata[index].pStart) }}</div>
                        <div class="card_header" style="width: 35%;" title="结束时间"><i class="pi pi-calendar-times"></i>{{
                            fdate(mydata[index].pEnd) }}</div>
                        <div class="card_header" style="width: 30%;" title="耗时"><i class="pi pi-stopwatch"></i>
                            {{ mydata[index].pCost + '天' }}</div>
                    </div>
                    <div v-if="props.pstyle == 'GroupMap'">
                        <span class="p-float-label">
                            <Knob :show-value="true" :model-value="Number(mydata[index].pCost)" valueTemplate="{value}%"
                                style="position: absolute;right: 1em;top: -2.5em;" />
                        </span>
                    </div>
                </div>


                <div class="clear"></div>
                <div>
                    <div v-for="{ pId, pLastTime, pCost, pProgress } in useCommon.jsons_o(mydata[index].today)" :key="pId">
                        <div style="font-size: 1em;border-top: 1px solid white;padding: 0.5em;margin-top: 1em;">
                            <div style="min-height:2em;">
                                <div>
                                    <div class="div_50_100">
                                        <div style="width: 65%;float:left;">
                                            <img src="/img/user03.jpg" class="rounded-circle" height="40"
                                                alt="Black and White Portrait of a Man" />
                                            {{ useCommon.format_date(pLastTime, "yyyy-MM-dd") }}
                                        </div>
                                        <div style="width: 35%;float:left;">
                                            <div style="padding-top:0.4em">
                                                耗时{{ pCost }}天
                                            </div>

                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="clear"></div>
                                </div>

                                <div style="padding-top:0.5em">
                                    {{ pProgress }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>

                <div class="clear"></div>
            </div>
            <div v-if="mydata[index]">
                <div v-if="mydata[index].children && mydata[index].isChildShow ">
                    <CommonPlan :isparent="mydata[index].pId" :datas="mydata[index].children" :userid="userid"
                        :setroledata="props.setroledata" :pstyle="props.pstyle" @datachange="datachange"></CommonPlan>
                </div>
                <div v-if="(mydata[index]) && mydata[index].is_ededit" style="z-index: 99;">
                    <div>
                        <div class="div_30_100">
                            <div class="div_50_100">
                                <span class="p-float-label margin_top">
                                    <Calendar date-format="yy/mm/dd" id="td_day" style="width: 100%;" :manualInput="true"
                                        showButtonBar placeholder="目标开始时间" v-model="td_data.pLastTime"
                                        v-on:update:model-value="findaffair(mydata[index].pId, td_data)" />
                                    <label for="td_day" style="color: white;">执行日期</label>
                                </span>

                            </div>
                            <div class="div_50_100">
                                <span class="p-float-label margin_top">
                                    <InputText id="tdCost" style="width: 100%;" type="number" maxlength="30"
                                        v-bind:title="td_data.pId" v-model="td_data.pCost" />
                                    <label for="tdCost" style="color: white;">耗时({{ td_data.pCost * 8 }}小时)</label>
                                </span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="div_70_100">
                            <div>
                                <span class="p-float-label margin_top">
                                    <Textarea id="td_descp" style="width: 100%;" v-model="td_data.pProgress" rows="1"
                                        cols="30" placeholder="今日进展" />
                                    <label for="td_descp" style="color: white;">今日进展</label>
                                </span>
                                <div class="p-inputgroup flex-1">
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
            <div style="text-align: center;" v-if="mydata[index] && mydata[index].canEdit == 1">
                <div v-if="mydata[index].pStatus == '已完成'">
                    <i class="icon_btn pi pi-check-circle"
                        @click="recheck_plan(mydata[index].index, mydata, mydata[index].pId);;"
                        :title="'重提该项目' + mydata[index].pId"></i>
                </div>
                <div v-else>
                    <i class="icon_btn pi pi-minus" @click="del_plan(mydata[index].index, mydata, mydata[index].pId);"
                        :title="'删除事项' + mydata[index].pId" v-if="!mydata[index].is_ededit"></i>
                    <i class="icon_btn pi pi-plus"
                        @click="add_child(mydata[index], props.pstyle); mydata[index].isChildShow = true;" title="增加子项目"
                        v-if="!mydata[index].is_ededit"></i>
                    <i class="icon_btn pi pi-lock" title="修改" @click="mydata[index].isChange = true;"
                        v-if="!mydata[index].isEdit && (!mydata[index].isChange) && !mydata[index].is_ededit"></i>
                    <i class="icon_btn pi pi-lock-open" title="保存修改" @click="upd_plan(mydata[index].pId, mydata[index])"
                        v-if="mydata[index].isChange && !mydata[index].is_ededit"></i>
                    <i class="icon_btn pi pi-comment" v-bind:title="mydata[index].pId" @click="opentoday(mydata[index])"
                        v-if="!mydata[index].is_ededit"></i>
                    <i class="icon_btn pi pi-times" v-if="mydata[index].is_ededit && td_data.pId > 0"
                        v-bind:title="'删除进展' + td_data.pId + '(' + td_data.pPlanID + ')'" @click="del_today(td_data);datachange()"
                        :disabled="td_data.pId < 0" />
                    <i class="icon_btn pi pi-cloud-upload" v-if="mydata[index].is_ededit"
                        v-bind:title="'增加或者更新进展' + td_data.pId + '(' + td_data.pPlanID + ')'"
                        @click="operate_myafairs(td_data); mydata[index].is_ededit = false;datachange()" />
                    <i class="icon_btn pi pi-sign-out" v-if="mydata[index].is_ededit"
                        v-bind:title="'退出编辑' + mydata[index].pId"
                        @click="mydata[index].is_ededit = false; mydata[index].isEdit = false" />

                    <i class="icon_btn pi pi-check-circle"
                        @click="finish_plan(mydata[index].index, mydata, mydata[index].pId);;"
                        :title="'完成该项目' + mydata[index].pId"
                        v-if="mydata[index].today && mydata[index].today.length > 0 && (!mydata[index].is_ededit)"></i>
                </div>

            </div>
            <div style="text-align: center;padding: 0.5em;">

            </div>
        </div>

    </div>
    <div class="clear"></div>

    <div style="text-align: center;padding: 0.5em;" v-if="props.pstyle != 'MyFinished'">
        <i class="nicon_btn pi pi-comment" :title="props.pheader" @click="add_input(mydata, props.pstyle)"
            v-if="isparent < 0">{{ props.pheader }}</i>

    </div>
    <div class="place1em"></div>
</template>
<script setup>
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import { defineProps,defineEmits,defineExpose } from 'vue';
import Textarea from 'primevue/textarea';
import useCommon from '@/services/useCommon';
import ObjectShare from './ObjectShare.vue';
import useCommonMap from '@/services/useCommonMap'
import Slider from 'primevue/slider';
import Knob from 'primevue/knob';
const props = defineProps({
    isparent: Number,
    datas: Array,
    userid: Number,
    pstyle: String,
    pheader: String,
    setroledata: Object,
    belonginfo: Object
});

const { mydata, td_data, sel_day, getrolevalue, add_plan, upd_plan,
    del_plan, del_childplan, fdate, add_child, add_input, cal_start, cal_end,
    finish_plan, operate_myafairs, del_today, opentoday, findaffair, getoneday, recheck_plan,getaffairs,getaffairsbyid } = useCommonMap(props)

async function getaffairslayer(oldobj,pid) {  
    console.log(pid)  
    oldobj.children= await getaffairsbyid(pid)
}
const emits = defineEmits(['datachange']);
async function datachange() {
    console.log(props.isparent,"datachange")
    emits("datachange")
    await getaffairs()
}

defineExpose({
    getaffairs
});

</script>