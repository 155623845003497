import useApi from "@/services/useApi";
const init_old = {

    min_height: 1100,
    max_heigh: 29999,
    contextmenu: 'forecolor cut copy bold underline codesample align image media table',
    menubar: true,
    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount autoresize',
    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
    tinycomments_mode: 'embedded',
    tinycomments_author: 'Fire.Su',
    autoresize_on_init: true,
    automatic_uploads: true,
    image_dimensions: true,    
    placeholder: "在这里输入文字",
    images_upload_handler: useApi.image_upload,
    selector: 'textarea',
    file_picker_callback: useApi.file_handler,
    content_style: 'img{max-width:100%;height:auto;} .mce-preview-object.mce-object-video {width:100% !important;}'
}

export default {
    init_old
}