<template>
  <div class="card">
    <ProjectList :project_id="props.project_id"></ProjectList>
  </div>
  
</template>

<script setup>
  import ProjectList from '@/components/ProjectList.vue'
  import {defineProps,onMounted,defineEmits,onBeforeUnmount } from "vue";
  import { useRoute } from 'vue-router';
  import useSlade from '@/services/useSlade';
  const props = defineProps({
      project_id: String
    })
  const route = useRoute();
  const emits = defineEmits(['add-button','remove-button']);
  onBeforeUnmount(() => {
  emits('remove-button', []);
});
  onMounted(() => {
    useSlade.inject_route(route)
    emits('add-button', []);
  })

  document.title = '[项目管理]-[碳硅锗兮]';
  document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
  document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
 </script>

