import api from '@/services/useApi';

const postapi = api.postapi;


async function getvalues(apiname,data) {
    const memdata = await postapi("firesu/free",
        {
            apiname: apiname,
            data0: data
        })
    if (memdata.data && memdata.data.data)
        return memdata.data.data
}
async function getvalue(apiname,data) {
   
    const memdata = await postapi("firesu/free",
        {
            apiname: apiname,
            data0: data
        })

    if (memdata.data && memdata.data.data[0])
        return memdata.data.data[0]
   
}

const get_blogs   =async (blogid)    =>{return await getvalues("get_blogs",blogid)}
const get_blog   =async (blogid)    =>{return await getvalue("get_blog",blogid)}
const get_files   =async (blogid)    =>{return await getvalues("get_files",blogid)}
const get_file   =async (blogid)    =>{return await getvalue("get_file",blogid)}
const get_allds   =async (blogid)    =>{return await getvalues("get_allds",blogid)}
const get_ocount   =async (blogid)    =>{return await getvalue("get_ocount",blogid)}
const get_ucount   =async (blogid)    =>{return await getvalue("get_ucount",blogid)}
const get_rusers   =async (blogid)    =>{return await getvalue("get_rusers",blogid)}
export default {
    get_blogs,get_blog,get_files,get_file,get_allds,get_ocount,get_ucount,get_rusers
}
