
<template>   
    <div id="scrollspy-collapsible">
        <div style="margin: 1em;padding: 1em;border-radius: 1em 1em;background-image:url(images/f4.jpg);text-align:center;">

            

            <div class="margin_top div_100">
                <div class="p-inputgroup flex-1 ">
                    <span class="p-float-label">
                        <Dropdown v-model="myobject.Project" :options="myother.Projects" optionLabel="pName" placeholder="项目名称"
                            class="w-full md:w-14rem" />
                        <label for="companyname">项目名称</label>
                    </span>
                </div>
            </div>             
            <div class="clear"></div>
            <div class="place1em"></div>
            <FileUpload mode="basic" name="demo[]" :disabled="!(myobject.Project && myobject.Project.pId)"
            :maxFileSize="1024000000" :multiple="true" :auto="true" chooseLabel="上传规格书" customUpload
            @uploader="customFileUploader" />
            
        </div>

    </div>
  </template>
      
  <script setup>
  import Dropdown from 'primevue/dropdown';
  import useApi from '@/services/useApi';
  import FileUpload from 'primevue/fileupload';
  import useSlade from "@/services/useSlade"
  import { ref,onMounted,defineEmits } from "vue";
import useCommon from '@/services/useCommon';
  
  let myobject = ref({});
  let myother = ref({})
  const customFileUploader = async (event) => {
    await useApi.uploadfile('api/File/datasheet', event);
    ondatachange()
  };
  const get_configs = async () => {
    let operojects=await useSlade.getcs_projects();
    myother.value.Projects = useCommon.removeKey(operojects,'canEdit');
    myother.value.Style1s = await useSlade.get_styles_s();
  }
  onMounted(() => {
    get_configs();
  })
  const emits = defineEmits(['ondatachange']);
  function ondatachange() {
    emits("ondatachange")
  }

  </script>
    
      