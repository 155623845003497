

/**按照规定的时间格式返回日期部分 */
function format_date(time, format) {
    var t = new Date(time);
    var tf = function (i) { return (i < 10 ? '0' : '') + i };
    return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        switch (a) {
            case 'yyyy':
                return tf(t.getFullYear());
                
            case 'MM':
                return tf(t.getMonth() + 1);
                
            case 'mm':
                return tf(t.getMinutes());
                
            case 'dd':
                return tf(t.getDate());
                
            case 'HH':
                return tf(t.getHours());
                
            case 'ss':
                return tf(t.getSeconds());
                
        }
    })
  }
 
  /**按先后书序返回非空对象，用于清除异常 */
  function get_object(val1,val2)
  {
    if(val2)
      return val2;
    else if(val1)
      return val1;
    else
      return "";
  }

  /**返回2022-09-29格式的日期部分 */
 

  function get_sdate(datett) {
    const dateObj = new Date(datett);
    return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
  }

  function numtostirng(number) {
    if(number>10000)
      return (number/10000).toFixed(1) + 'w'
    else if(number>1000)
      return (number/1000).toFixed(1) + 'k'
    else
      return number
  }


  function stringtodate(datestr)
  {
    console.log(datestr)
    return new Date(datestr);
  }

  
function cvalue_item(cvalue,items,keyf)
{   
  let new_arr=[]
  items.forEach(obj=>{
  if(obj[keyf]==cvalue)
  { 
    new_arr.push(obj);
  } 
  })
  return new_arr;
}

function jsono_s(obj)
{
  if(obj==null)
    return ''
  else
  {
    if (typeof obj === 'object')
      return JSON.stringify(obj)
    else if((typeof obj === 'string'))
      return obj
    else
      return ""
  }
}
function jsons_o(obj)
{
  if(obj==null)
    return {}
  else
  {
    if (typeof obj === 'object')
      return obj
    else if((typeof obj === 'string'))
      return JSON.parse(obj)
    else
      return {}
  }
}

function newjsons_o(oldobj,nobj)
{
  if(nobj!=null)
  {
    if (typeof nobj === 'object')
      oldobj= nobj
    if((typeof nobj === 'string'))
    {
      if(nobj.length>3)
        oldobj= JSON.parse(nobj)
    }
  }
}

function citem_value(items,keyf)
{   
  let new_str=''
  items.forEach(obj=>{     
      new_str=new_str+'{'+(obj[keyf])+'};';    
  })
  if(new_str.length>0)
    new_str=new_str.slice(0,new_str.length-1)
  return new_str;
}

function mo_s(items,keyf)
{   
  let new_str=''
  items.forEach(obj=>{     
      new_str=new_str+'{'+(obj[keyf])+'};';    
  })
  if(new_str.length>0)
    new_str=new_str.slice(0,new_str.length-1)
  return new_str;
}

function ms_mo(items,name,value)
{   
  let values=value.split(';')
  var len=values.length;
  if(items.length<len)
  {
    
    values.forEach(obj=>{   
      let tobj={}  
      tobj[name] =  obj.replace("{","").replace("}","")
      items.push(tobj)
    })
    return items;
  }
  let xx=0;
  values.forEach(obj=>{     
    items[xx][name] =  obj.replace("{","").replace("}","")
    xx++
  })
  return items;
}

function s_o(name,value)
{   
  let new_obj={}
  new_obj[name]=value  
  return new_obj;
}

function ss_o(value,name)
{   
  let new_obj={}
  new_obj[name]=value  
  return new_obj;
}

function ms_o(obj,name,value)
{ 
  obj[name]=value  
  return obj;
}

function o_s(obj,name)
{ 
  if(obj==null)
    return ""

  if(obj[name])
    return obj[name];
  else if(obj)
    return obj
  else
    return ''
}
function null_s(obj)
{ 
  if(obj)
    return obj;
  else
    return ''
}

function o_o(obj) {
  let copiedObj = JSON.parse(JSON.stringify(obj)); // 创建原始对象的深拷贝
  return copiedObj; // 返回修改后的副本
}

const removeKey = (array, keyToDelete) => {
  return array.map(item => {
    const newItem = { ...item }; // 创建对象的一个浅拷贝
    delete newItem[keyToDelete]; // 删除指定的键
    return newItem;
  });
};


function add_index(obj)
{
  let index=0;
  obj.forEach((item)=>{
    item.index=index
    index++;
  })
  return obj;
}


  export default{format_date,get_object,stringtodate,get_sdate,cvalue_item,citem_value,s_o,o_s,ms_o,mo_s,ms_mo,null_s,ss_o,
    o_o,jsono_s,jsons_o,newjsons_o,numtostirng,add_index,removeKey
  }