
<template>
  <div>
      <div v-if="showdatas.length>0">
        <div class="lhide">
          <DataTable :value="showdatas">
              <Column field="slevel" header="排名" sortable style="width: 5em"></Column>
              <Column header="名称" style="width: 5em">
                  <template #body="slotProps">
                      
                      
                      <a @click="getinfo(slotProps.data.splace,slotProps.data.scode)" :title="slotProps.data.shistory">{{ slotProps.data.sname }}</a>
                  </template>
              </Column>
              <Column field="scode" header="编码" sortable style="width: 5em"></Column>
              <Column field="sprice" header="价格" sortable style="width: 5em"></Column>
              <Column field="szd" header="涨幅" sortable style="width: 5em"></Column>            
              <Column field="scount" header="次数" sortable style="width: 5em"></Column>
              <Column field="sout" header="回落" sortable style="width: 5em"></Column>
              <!-- <Column field="sinsdate" header="时间" sortable style="width: 5em"></Column> -->
              <Column header="状态" style="width: 5em">
                  <template #body="slotProps">
                    <Button icon="pi pi-eye"  style="width: 2em;height: 2em;margin: 0.1em;" title="查看股票信息" @click="search_val(slotProps.data.splace,slotProps.data.scode)" />
                    <Button icon="pi pi-plus"  style="width: 2em;height: 2em;margin: 0.1em;" title="查看股票信息" @click="search_val(slotProps.data.splace,slotProps.data.scode)" />
                  </template>
              </Column>
          </DataTable>
        </div>
        <div class="lshow">
          <div v-for="{ scode,slevel,sname,sout,scount,sinsdate,sprice,szd,sstatus,shistory } in showdatas" :key="scode">
            <div style="border: 1px solid #e0e0e0;padding: 0.5em; margin: 0.5em;border-radius: 0.5em 0.5em;text-align: left;">
              <div class="div_50">({{ slevel }}){{sname}}</div>
              <div class="div_50">{{scode}}</div>
              <div>
                  价格 <label>{{ sprice }}</label> 元,涨幅 <label>{{ szd }}% </label>,{{ sstatus }}
              </div>
              <div :title="shistory">
                  {{ sinsdate }}出现,出现 <label>{{ scount }} </label>次,回落 <label>{{ sout }}</label> 次
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
          <div style="padding: 1em;">
              暂无数据  
          </div>
      </div>
  </div>
</template>
    
<script setup>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import useApi from '@/services/useApi';
import { ref, onMounted,defineProps,watchEffect,defineEmits } from "vue";
const props = defineProps({
    mydata:Array
});

let showdatas = ref([])
const listshow = async () => {
  if(props.mydata && props.mydata.length>0){
    showdatas.value=props.mydata
    
  }
}
onMounted(() => {
    listshow();
})

watchEffect(() => {
  showdatas.value=props.mydata
})


let infodata = ref([])
const getinfo = async (splace,scode) => {
  infodata.value=useApi.freeget("http://qt.gtimg.cn/q="+splace+scode)
}

const emits = defineEmits(['onsearch']);
function search_val(splace,scode) {
    emits("onsearch", splace,scode)
}
</script>
  
    