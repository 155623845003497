
<template>
  <div class="card">
      <div v-for="({sId,sName,sBuyDescription,sCount},index) in stocktypedatas" :key="sId">        
        <div class="card" v-if="sCount>0">
          <div style="margin: 1em;padding: 1em;border-radius: 1em 1em;">
            <h3>{{ sName }}</h3>
            {{ sBuyDescription }}
            <div class="place1em"></div>
            <h4>持仓股票</h4>
            <div class="place1em"></div>
            <StockPanleBuyTable :sId="sId+''" :ref="el => { if (el) buyComponents[index] = el }" @onstockchannge="async()=>{
              await listshow();
              await callSaleChildMethod(index)
              ;}"></StockPanleBuyTable>
            
          </div>   
        </div>
        
      </div>
  </div>
</template>
    
<script setup>
import useSlade from '@/services/useSlade';
import { ref, onMounted,watchEffect } from "vue";
import useCommon from '@/services/useCommon';
import StockPanleBuyTable from './StockPanleBuyTable.vue';

const stocktypes=ref([])
const stocktypedatas=ref([])
const is_fill=ref(false)
const stockcash=ref({})
stockcash.value.sBeforeMoney=0
stockcash.value.sNowMoney=0

const buyComponents = ref([]);
const saleComponents = ref([]);


const callSaleChildMethod = async(index) => {
  console.log(saleComponents.value)
  console.log(saleComponents.value)
  if(saleComponents.value.length>index){
    await saleComponents.value[index].listshow()
  }
  
};

const listshow = async () => {
  stocktypes.value=await useSlade.get_stocktypes()
  stocktypedatas.value=useCommon.o_o(stocktypes.value)
  is_fill.value=(stocktypes.value.length>0)
}



onMounted(async() => {
    listshow();
})

watchEffect(() => {
  stocktypes.value
})



// const emits = defineEmits(['onsearch']);
// function search_val(splace,scode) {
//     emits("onsearch", splace,scode)
// }
</script>

<style >
.up{
  font-weight: bold;
  color: red;
}

.down{
  font-weight: bold;
  color: green;
}

</style>
  
    