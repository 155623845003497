
<template id="blog_show">
  <div id="carouselBasicExample" class="carousel slide carousel-fade" data-mdb-ride="carousel">
  <!-- Indicators -->
  <div class="carousel-indicators">
    <button
      type="button"
      data-mdb-target="#carouselBasicExample"
      data-mdb-slide-to="0"
      class="active"
      aria-current="true"
      aria-label="Slide 1"
    ></button>
    <button
      type="button"
      data-mdb-target="#carouselBasicExample"
      data-mdb-slide-to="1"
      aria-label="Slide 2"
    ></button>
    <button
      type="button"
      data-mdb-target="#carouselBasicExample"
      data-mdb-slide-to="2"
      aria-label="Slide 3"
    ></button>
  </div>

  <!-- Inner -->
  <div class="carousel-inner" style="max-height: 200px;">
    <!-- Single item -->
    <div class="carousel-item active">
      <img src="/img/tm-bg-7.jpg" class="d-block w-100" alt="Sunset Over the City" />
      <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </div>
    </div>

    <!-- Single item -->
    <div class="carousel-item">
      <img src="/img/tm-bg-8.jpg" class="d-block w-100" alt="Canyon at Nigh"/>
      <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
    </div>

    <!-- Single item -->
    <div class="carousel-item">
      <img src="/img/tm-bg-6.jpg" class="d-block w-100" alt="Cliff Above a Stormy Sea"/>
      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
      </div>
    </div>
  </div>
  <!-- Inner -->

  <!-- Controls -->
  <button class="carousel-control-prev" type="button" data-mdb-target="#carouselBasicExample" data-mdb-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-mdb-target="#carouselBasicExample" data-mdb-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
  <div style="height: 3em;"></div>
 
  <h3>{{blog.bName}}</h3>  
  
  <div >
    <nav aria-label="breadcrumb" class="lhide">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/bloglist">首页</a></li>
        <li class="breadcrumb-item"><a href="/bloglist">笔记</a></li>
        <li class="breadcrumb-item active " aria-current="page" :blog_id="blog.Id">{{blog.bName}}  </li>
      </ol>
    </nav>
  
<!-- Carousel wrapper -->
<div class="padding">
<div :innerHTML="blog.bText" style="text-align: left;line-height: 2em;font-family: Arial, Helvetica, sans-serif;" class="line-numbers"></div> 


<div style="height: 2em;;"></div>
<div style="border-top: 1px rgb(232, 231, 231) solid;padding: 0.5em;text-align: right;">
  <a :href="'/blogs/write/'+blog_id" v-if="blog.canEdit" title="笔记目录"><Button icon="pi pi-bars" style="margin: 0.1em;position: fixed;right: 1em;bottom: 8em;z-index: 99;border-radius: 50% 50%;width: 3em;height: 3em;"/></a>   

  <a :href="'/blogs/write/'+blog_id" v-if="blog.canEdit" title="编辑笔记"><Button icon="pi pi-pencil" style="margin: 0.1em;position: fixed;right: 1em;bottom: 4.5em;z-index: 99;border-radius: 50% 50%;width: 3em;height: 3em;"/></a>   
</div>
</div>

<div style="height: 2em;;"></div>
<div class="padding" v-if="authed"> 
  所有评论
  <CommonPlan :isparent="-1" pstyle="Msg" pheader="评论博客" :setroledata="setroledata"></CommonPlan>
</div>
<div class="padding" v-else>
  登录后查看评论
</div>
</div>
</template>
<script setup>
  import { ref,defineProps,onMounted,defineEmits,onBeforeUnmount } from "vue";
  import Button from 'primevue/button';
  import CommonPlan from '@/components/PlanMsg.vue';
  import useFree from "@/services/useFree";
  import "prismjs/themes/prism-tomorrow.min.css"//引入代码高亮主题（这个去node_modules的安装prismjs中找到想使用的主题即可）
  import "prismjs/plugins/line-numbers/prism-line-numbers.min.js"
  import "prismjs/plugins/line-numbers/prism-line-numbers.min.css"//行号插件的样式
  import Prism from "prismjs"//导入代码高亮插件的core（里面提供了其他官方插件及代码高亮样式主题，你只需要引入即可）
  import { useStore } from "vuex";
  const props = defineProps({        
        blog_id: String
    })

  const emits = defineEmits(['add-button','remove-button']);
  onBeforeUnmount(() => {
    emits('remove-button', []);
  });
  onMounted(() => {
    if (props.blog_id && props.blog_id.length > 0) {
      search_blog();
      }
    emits('add-button', []);
  })
  const store = useStore();
  let authed = ref(store.state.authed);

  let blog=ref({})
  let setroledata=ref({})
  const search_blog=async ()=>{
    blog.value.Id=props.blog_id.replace('blog_','')
    let result={}
    console.log(authed.value)
    result= await useFree.get_blog(blog.value.Id)
    if(result&&result.bId)
    {
      document.title='['+result.bName+']-[笔记]-[碳硅锗兮]';
      blog.value=result
      setTimeout(function() {
        Prism.highlightAll();
                  }, 1000);
    }
    
    setroledata.value={rdRange:{rdRange: '所有人可见' },wrRange:{ wrRange: '仅自己编辑' },sShareGroup:"{\"bId\":"+blog.value.bId+",\"bName\":\""+blog.value.bName+"\"}"}
  }
   
</script>


<style>
  /* Styles required only for the example above */
  .scrollspy-example-collapsible {
    position: relative;
    height: 200px;
    overflow: auto;
  }
</style>


