import store from '@/store';
const salert =(msg)=> {
	localStorage.setItem('errmsg', msg);
    store.commit('errAlert')
}

const scheck =(msg)=> {
	localStorage.setItem('errmsg', msg);
    localStorage.setItem('needcheck', true);
    store.commit('errAlert')
}
export default {salert,scheck}
