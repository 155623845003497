
<template>
    <div>
      <!-- Scrollspy -->
      <div id="scrollspy-collapsible">        
            <div style="margin: 1em;padding: 1em;border-radius: 1em 1em;text-align: center;">
              <div style="font-weight: bold;">
                “良好的供应商关系是项目成功的一半”
              </div>
              
  
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="vender.Name" />
                    <label for="companyname">姓名</label>
                    <Button icon="pi pi-search" title="查找供应商" @click="search_blog(vender)" />
                  </span>
                </div>
              </div>

              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <MultiSelect v-model="vender.ManuName" :options="objoptions.vNames" optionLabel="vName" placeholder="厂商名称"
                      class="w-full md:w-14rem" />
                    <label for="companyname">厂商名称</label>
                  </span>
                </div>
              </div>
  
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="vender.Email" />
                    <label for="companyname">邮箱</label>
  
                  </span>
                </div>
              </div>
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="vender.Phone" />
                    <label for="companyname">电话</label>
  
                  </span>
                </div>
              </div>
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="vender.WeChat" />
                    <label for="companyname">微信</label>
  
                  </span>
                </div>
              </div>
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="vender.GW" />
                    <label for="companyname">岗位</label>
  
                  </span>
                </div>
              </div>
  
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="vender.Place" />
                    <label for="companyname">办公地点</label>
                  </span>
                </div>
              </div>
  
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="vender.Company" />
                    <label for="companyname">公司名称</label>
                  </span>
                </div>
              </div>
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText id="companyname" v-model="vender.HomePage" />
                    <label for="companyname">公司主页</label>
                  </span>
                </div>
              </div>
  
              <div class="margin_top div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="vender.ZW" :options="objoptions.ZWs" editable optionLabel="ZW" placeholder="可见范围"
                      class="w-full md:w-14rem" />
                    <label for="companyname">职位</label>
  
                  </span>
                </div>
              </div>
  
              <div class="div_33_100 margin_top">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="vender.Style" :options="objoptions.Styles" editable optionLabel="Style"
                      placeholder="厂家类型" class="w-full md:w-14rem" />
                    <label for="uGangwei">厂家类型</label>
                  </span>
                </div>
              </div>
              <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue" :sharedata="vender"></ObjectShare>
              <div class="clear"></div>
              <div class="place1em"></div>
              <div>
                <Button icon="pi pi-plus" v-if="vender.Id <= 0" class="margin" title="上传资料" label="新增"
                  @click="create_blog()" />
                <Button icon="pi pi-refresh" v-if="vender.Id > 0" class="margin" title="更新资料" label="更新"
                  @click="update_blog()" />
                <Button icon="pi pi-times" v-if="vender.Id > 0" class="margin" title="删除资料" label="删除"
                  @click="delete_blog()" />
              </div>
              <div class="place1em"></div>
              <div>
                <div class="div_25_100" v-for="{ Name, Phone, ZW, Id,Email,WeChat,ManuName } in venderlist" :key="Id" style="padding: 0.5em;text-align: left;cursor: pointer;border: 1px solid #efefef; margin: 0.1em; border-radius: 0.5em;width: auto;float: left;"
                  @click="vender.Id = Id; search_blog()">
                  <a :title="ManuName+ ((Email)?' ,'+Email:'')+ ((Phone)?' ,'+Phone:'')+((WeChat)?' ,'+WeChat:'')"><i style="font-weight: bold;font-size: large;">{{ Name }}</i>(<i style="font-size: small;">{{ ZW }}</i>)</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 1em;"></div>
  </template>
    
  <script setup>
  import ObjectShare from '@/components/ObjectShare.vue'
  import { ref, onMounted, defineEmits } from "vue";
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  import Dropdown from 'primevue/dropdown';
  import useCommon from '@/services/useCommon';
  import useSlade from '@/services/useSlade';
  import useAlert from '@/services/useAlert';
  import MultiSelect from 'primevue/multiselect';
  
  let vender = ref({});
  let venderlist = ref([])
  let objoptions = ref({})
  let sendshare = ref({});
  let getshare = ref({});
  sendshare.value.Id = -1
  const getrolevalue = (obj) => {
    sendshare.value = obj
  }
  const get_configs = async () => {
    let ovnames= await useSlade.get_styles_n();
    objoptions.value.vNames =useCommon.removeKey(ovnames,'canEdit')
    objoptions.value.ZWs = []
    objoptions.value.Styles = []
  }
  
  onMounted(() => {
    get_configs();
  })
  
  
  const beforepost = (obj) => {
    if (obj.ManuName)
      obj.ManuName = useCommon.jsono_s(obj.ManuName)
    if (obj.ZW)
      obj.ZW = useCommon.o_s(obj.ZW, "ZW")
    if (obj.Style)
      obj.Style = useCommon.o_s(obj.Style, "Style")
  }
  
  const create_blog = async () => {
    if (vender.value.Name && vender.value.ManuName &&(vender.value.Phone||vender.value.Email||vender.value.WeChat)) {
        let postgroup = useCommon.o_o(vender.value)
        beforepost(postgroup);
        useSlade.add_vender(postgroup, sendshare.value);
        ondatachange()
    }
    else
      useAlert.salert("信息不完整")
  }
  
  const update_blog = async () => {
    if (vender.value.Name && vender.value.ManuName &&(vender.value.Phone||vender.value.Email||vender.value.WeChat)) {
      let postgroup = useCommon.o_o(vender.value)
      beforepost(postgroup);
      await useSlade.upd_vender(postgroup, sendshare.value);
      ondatachange()
    }
    else
      useAlert.salert("信息不完整")
  }
  
  const delete_blog = async () => {
    let postgroup = useCommon.o_o(vender.value)
    beforepost(postgroup);
    await useSlade.del_vender(postgroup, sendshare);
    ondatachange()
  }
  
  const search_blog = async () => {
    let postgroup = useCommon.o_o(vender.value)
    beforepost(postgroup);
    let getresult = await useSlade.get_vender(postgroup);
    if (getresult && getresult.Name) {
      getshare.value = getresult
      vender.value = getresult
      vender.value.ManuName = useCommon.jsons_o(vender.value.ManuName)
    }
    else {
      vender.value.Id = -1;
    }
    venderlist.value = await useSlade.get_venders(postgroup);
    
  }
  
  const emits = defineEmits(['ondatachange']);
  function ondatachange() {
    emits("ondatachange")
  }
  </script>
  
    