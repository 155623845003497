<template>
    <div>
        <div class="clear"></div>
        <div :class="'margin_top ' + widthclass">
            <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                    <Dropdown v-model="select.sShareGroup" :options="share.groups" :disabled="props.disabled == true"
                        optionLabel="uGroupName" @change="getgroupuer(select.sShareGroup)" placeholder="执行小组"
                        class="w-full md:w-14rem" />
                    <label :style="labelstyle" for="group">所属小组</label>

                </span>
            </div>
        </div>
        <div :class="'margin_top ' + widthclass">
            <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                    <Dropdown v-model="select.rdRange" :options="share.rdtyles" :disabled="props.disabled == true"
                        optionLabel="rdRange" @change="ondatachange" placeholder="可见范围" class="w-full md:w-14rem" />
                    <label :style="labelstyle" for="rdtyle">可见范围</label>

                </span>
            </div>
        </div>
        <div :class="'margin_top ' + widthclass" v-if="select.rdRange.rdRange == '自定义'">
            <span class="p-float-label">
                <MultiSelect id="rdmembers" style="width: 100%;" v-model="select.rdUsers" display="chip"
                    :disabled="props.disabled == true" @change="ondatachange" :options="share.members" optionLabel="uName"
                    placeholder="可见人员" :maxSelectedLabels="30" class="w-full md:w-20rem" />
                <label :style="labelstyle" for="rdmembers">可见人员</label>
            </span>
        </div>
        <div :class="'margin_top ' + widthclass">
            <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                    <Dropdown v-model="select.wrRange" :options="share.wrtyles" optionLabel="wrRange" placeholder="编辑权限"
                        :disabled="props.disabled == true" @change="ondatachange" class="w-full md:w-14rem" />
                    <label :style="labelstyle" for="uGangwei">编辑权限</label>
                </span>
            </div>
        </div>
        <div :class="'margin_top ' + widthclass" v-if="select.wrRange.wrRange == '自定义'">
            <span class="p-float-label">
                <MultiSelect style="width: 100%;" v-model="select.wrUsers" display="chip" @change="ondatachange"
                    :options="share.members" optionLabel="uName" placeholder="编辑人员" :maxSelectedLabels="30"
                    class="w-full md:w-20rem" />
                <label :style="labelstyle" for="wrmembers">编辑人员</label>
            </span>
        </div>
        <div class="clear"></div>
    </div>
</template>
<script setup>
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import { ref, onMounted, defineEmits, defineProps, watchEffect } from "vue";
import useCommon from '@/services/useCommon';
import useSlade from '@/services/useSlade';
const props = defineProps({
    widthclass: String,
    labelstyle: String,
    sharedata: Object,
    disabled: Boolean,
    setroledata: Object
})

let select = ref({})
let share = ref({})
let widthclass = ref('div_33_100')
let labelstyle = ref('')
share.value.rdtyles = [{ rdRange: '仅自己可见' }, { rdRange: '组内成员可见' }, { rdRange: '所有人可见' }, { rdRange: '自定义' }]
share.value.wrtyles = [{ wrRange: '仅自己编辑' }, { wrRange: '组内成员可编辑' }, { wrRange: '所有人可编辑' }, { wrRange: '自定义' }]
share.value.members = ref([])

const get_binfo = async () => {
    share.value.groups = await useSlade.get_joindgroup();
    if (share.value.groups.length > 0) {
        // if(!select.value.sShareGroup)
        //     select.value.sShareGroup = share.value.groups[0];
        await getgroupuer(select.value.sShareGroup)
    }
    // if (props.setroledata)
    //     setrole(props.setroledata)

}
const getgroupuer = async (group) => {
    share.value.members = await useSlade.get_joindmems(group)
    
    ondatachange()
}




select.value.rdRange = { rdRange: '仅自己可见' }
select.value.wrRange = { wrRange: '仅自己编辑' }
select.value.rdUsers = [];
select.value.wrUsers = [];

onMounted(() => {
    get_binfo();
    widthclass.value = props.widthclass
    labelstyle.value = (props.labelstyle) ? "color:" + props.labelstyle + ";" : ""

})




const setrole = (obj) => {
    select.value.wrRange = useCommon.jsons_o(obj.wrRange)
    select.value.rdRange = useCommon.jsons_o(obj.rdRange)
    select.value.rdUsers = obj.rdUsers? useCommon.jsons_o(obj.rdUsers):[]
    select.value.wrUsers = obj.wrUsers?useCommon.jsons_o(obj.wrUsers):[]
    select.value.sShareGroup = useCommon.jsons_o(obj.sShareGroup)
}

const emits = defineEmits(['ondatachange']);
function ondatachange() {
    let senvalue = useCommon.o_o(select.value);
    
    senvalue.wrRange = useCommon.jsono_s(select.value.wrRange)
    senvalue.rdRange = useCommon.jsono_s(select.value.rdRange)
    senvalue.rdUsers = useCommon.jsono_s(select.value.rdUsers)
    senvalue.wrUsers = useCommon.jsono_s(select.value.wrUsers)
    senvalue.sShareGroup = useCommon.jsono_s(select.value.sShareGroup)
   
    emits("ondatachange", (senvalue))
}

watchEffect(() => {
    props.sharedata
    if (props.sharedata && props.sharedata.sShareGroup) {
        setrole(props.sharedata)
    }
    else if (props.setroledata && props.setroledata.sShareGroup) {
        setrole(props.setroledata)
    }
})


</script>