<template>
    
    <div v-if="props.cdata&&props.cdata.datasets&&props.cdata.datasets[0].data&&props.cdata.datasets[0].data.length">
     <Chart id="helli" :type="ctype" :data="props.cdata" :options="chartOptions" />
    </div>
</template>

<script setup>
import { ref,defineProps,watchEffect } from "vue";
import Chart from 'primevue/chart';

const props=  defineProps({
        clabel:String,        
        cdata:Object,       
        ctype:String
    }) 

const chartOptions = ref({
    scales: {
        y: {
            beginAtZero: true
        }
    },
    plugins: {
        legend: {
            labels: {
                usePointStyle: true
            }
        }
    }
});
watchEffect(() => {
    props.cdata;
})

</script>