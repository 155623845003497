<template>
    <div>

        <div class="place1em"></div>
        <div>
            <OrganizationChart v-model:selectionKeys="selection" :value="group_users" collapsible selectionMode="multiple">
                <template #person="slotProps">
                    <div class="flex flex-column">
                        <div class="flex flex-column align-items-center" style="width: 8em;overflow: hidden;">

                            <img :alt="slotProps.node.data.name" :src="slotProps.node.data.image" class="mb-3 w-3rem h-3rem"
                                @click="select_member(slotProps.node.data.name)" /><br />
                            <span class="font-bold mb-2">{{ slotProps.node.data.uName }}</span><br />
                            <span class="font-bold mb-2">{{ slotProps.node.data.uRole }}</span><br />
                            <div class="place1em"></div>
                            <div style="padding-top:0.5em;">
                                <i v-if="slotProps.node.data.isadmin"
                                    @click="toast.add({ severity: 'warn', summary: '确定删除小组吗?', detail: 'Proceed to confirm', group: 'bc' });"
                                    class="pi pi-trash" style="color: rgb(223, 77, 77);">解散</i>
                                <FireToast v-if="slotProps.node.data.isadmin" @clickyes="delete_group(slotProps.node.data)"
                                    group="bc"></FireToast>

                                <i v-if="slotProps.node.data.joining"
                                    @click="toast.add({ severity: 'warn', summary: '确定批准' + slotProps.node.data.uName + '进入小组吗?', detail: 'Proceed to confirm', group: 'bbd' });"
                                    class="pi pi-check-circle" style="color: rgb(31, 204, 88);">批准</i>
                                <FireToast v-if="slotProps.node.data.joining"
                                    @clickyes="agree_memgroup(slotProps.node.data, props.grpname)" group="bbd"></FireToast>

                                <i v-if="slotProps.node.data.joined"
                                    @click="toast.add({ severity: 'warn', summary: '确定将' + slotProps.node.data.uName + '踢出小组吗?', detail: 'Proceed to confirm', group: 'bbe' });"
                                    class="pi pi-check-circle" style="color: rgb(156, 18, 59);">开除</i>
                                <FireToast v-if="slotProps.node.data.joined"
                                    @clickyes="delete_memgroup(slotProps.node.data)" group="bbe"></FireToast>

                                <i v-if="slotProps.node.data.isjoined"
                                    @click="toast.add({ severity: 'warn', summary: '确定取消申请吗?', detail: 'Proceed to confirm', group: 'bbf' });"
                                    class="pi pi-check-circle" style="color: rgb(156, 18, 59);"
                                    :title='slotProps.node.key'>取消申请</i>
                                <FireToast v-if="slotProps.node.data.isjoined"
                                    @clickyes="delete_memgroup(slotProps.node.data)" group="bbf"></FireToast>

                                <i v-if="slotProps.node.data.hasjoined"
                                    @click="toast.add({ severity: 'warn', summary: '确定退出小组吗?', detail: 'Proceed to confirm', group: 'bbg' });"
                                    class="pi pi-check-circle" style="color: rgb(156, 18, 59);">退出小组</i>
                                <FireToast v-if="slotProps.node.data.hasjoined"
                                    @clickyes="delete_memgroup(slotProps.node.data)" group="bbg"></FireToast>
                            </div>

                        </div>
                    </div>
                </template>
                <template #default="slotProps">
                    <span>{{ slotProps.node.label }}</span>
                </template>
            </OrganizationChart>
        </div>
        <div style="text-align: center;">
            <a href="/projects" style="margin:0.1em"><Button icon="pi pi-mobile" label="项目" /></a>
            <a href="/myself" style="margin:0.1em"><Button icon="pi pi-link" label="事务" /></a>
            <a href="/myself" style="margin:0.1em"><Button icon="pi pi-dollar" label="考核" /></a>
        </div>
    </div>
</template>

<script setup>
import OrganizationChart from 'primevue/organizationchart';
import Button from 'primevue/button';
import FireToast from '@/components/FireToast.vue';
import useSlade from '@/services/useSlade';
import { ref, onMounted, defineProps, watchEffect, defineEmits } from "vue";
import { useToast } from "primevue/usetoast";
const toast = useToast();

const props = defineProps({
    grpid: Number,
    grpname: String,
    uAccount: Number
})

let group_users = ref({})
let selection = ref({})

const get_members = async () => {
    groupinfo.value.Id = props.grpid;
    group_users.value.data = await useSlade.get_group(groupinfo.value);
    if (group_users.value.data&&group_users.value.data.uId > 0) {
        group_users.value.data.image = 'img/amyelsner.png',
        group_users.value.key = group_users.value.data.Id;
        group_users.value.type = 'person';
        group_users.value.data.isadmin = (Number(group_users.value.data.uManager) == Number(props.uAccount));
        group_users.value.children = [];
        let getmemdatas = await useSlade.get_joinmems(groupinfo.value);
        if (getmemdatas&&getmemdatas.length > 0) {
            getmemdatas.forEach(element => {
                let memberinfo = {};
                memberinfo.data = element;
                memberinfo.data.image = 'img/amyelsner.png'
                memberinfo.data.isjoined = (Number(element.uId) == Number(props.uAccount) && element.uStatus == '申请加入')
                memberinfo.data.joining = (Number(element.uManager) == Number(props.uAccount) && element.uStatus == '申请加入')
                memberinfo.data.joined = (Number(element.uManager) == Number(props.uAccount) && element.uStatus == '正常')
                memberinfo.data.hasjoined = (Number(element.uId) == Number(props.uAccount) && element.uStatus == '正常')
                memberinfo.type = 'person'
                memberinfo.key = element.Id;
                group_users.value.children.push(memberinfo);
            })
        }
    }
    
}

let groupinfo = ref({})

onMounted(() => {

})

const delete_memgroup = (gpid) => {
    useSlade.delete_memgroup(gpid)
    get_members(props.grpid);
    re_get_members();
}

const agree_memgroup = (gpid) => {
    useSlade.agree_memgroup(gpid)
    get_members(props.grpid);
    re_get_members();
    re_get_mygroup();
}

const delete_group = (gpid) => {
    useSlade.delete_group(gpid)
    get_members(props.grpid);
    re_get_mygroup();
}

const emits = defineEmits(['re_get_members', 're_get_mygroup']);
function re_get_members() {
    emits("re_get_members")
}

function re_get_mygroup() {
    emits("re_get_mygroup")
}
watchEffect(() => {
    props.grpid;
    get_members(props.grpid);
})
</script>

<style>
.back_green {

    font-weight: bold;
}
</style>