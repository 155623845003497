<template>
    <Toast position="center" :group="props.group">
        <template #message="slotProps">
            <div class="flex flex-column align-items-center" style="flex: 1">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <div class="font-bold text-xl my-3">{{ slotProps.message.summary }}</div>
                </div>
                <div class="flex gap-2" style="text-align:center">
                    <Button style="margin:0.5em" severity="success" label="Yes"
                        @click="clickyes(); toast.removeAllGroups();"></Button>
                    <Button style="margin:0.5em" severity="secondary" label="No"
                        @click="toast.removeAllGroups();"></Button>
                </div>
            </div>
        </template>
    </Toast>
</template>
<script setup>
import { defineExpose, defineProps,defineEmits } from 'vue';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import { useToast } from "primevue/usetoast";
const toast = useToast();
const props = defineProps({
    group: String
})
const showTemplate = (msg, gpname) => {
    toast.add({ severity: 'success', summary: msg, group: gpname });
};


defineExpose({
    showTemplate
});

const emits = defineEmits(['clickyes']);
async function clickyes() {
    toast.removeAllGroups()
    emits("clickyes")
}

</script>