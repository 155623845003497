
<template>
  <div>
    <div class="card">
        <div>
          <div style="margin: 1em;padding: 1em;border-radius: 1em 1em;">           
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="stocktype.sName" />
                  <label for="companyname">策略名称</label>
                  <Button icon="pi pi-search"  @click="useSlade.get_stocktype(stocktype)" />
                  <Button icon="pi pi-plus"  @click="async()=>{ await useSlade.add_stocktype(stocktype);listshow()}" />
                  <Button icon="pi pi-refresh"  @click="async()=>{ await useSlade.upd_stocktype(stocktype);listshow()}" />
                </span>
              </div>
            </div>
            <div class="margin_top div_66_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="stocktype.sBuyDescription" />
                  <label for="companyname">策略描述</label>
                </span>
              </div>
            </div>
            <div class="clear"></div>
            <div class="place1em"></div>
            <div v-if="is_fill">
              <DataTable :value="stocktypes">
                  <Column field="sId" header="序号" style="width: 5em"></Column>                
                  <Column field="sName" header="策略名称" style="width: 5em"></Column>
                  <Column field="sBuyDescription" header="策略描述" style="width: 25em"></Column>
                  <Column header="状态" style="width: 5em">
                  <template #body="slotProps">
                    <Button icon="pi pi-check"  style="width: 2em;height: 2em;margin: 0.1em;" title="查看股票信息" @click="stocktype=slotProps.data" />
                    <Button icon="pi pi-trash"  style="width: 2em;height: 2em;margin: 0.1em;" title="查看股票信息" @click="async()=>{ await useSlade.del_stocktype(slotProps.data);listshow()}" />
                  </template>
              </Column>
              </DataTable>
              
            </div>
            <div v-else>
                <div style="padding: 1em;">
                    暂无数据  
                </div>
            </div>
          </div>
        </div>
        
        
      </div>
      <div class="place1em"></div>
      <div class="card" v-if="is_fill">
        <div style="margin: 1em;padding: 1em;border-radius: 1em 1em;">  
            
            
            <div class="margin_top div_66_100">              
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <Dropdown id="sbuystyle" v-model="sbuystyle"  :options="stocktypes" optionLabel="sName" @change="get_index"
                    placeholder="选择股票策略" class="w-full md:w-14rem" />
                  <label for="sbuystyle">选择股票策略</label>
                </span>
              </div>
            </div>
            <div class="margin_top div_34_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="sBeforeMoney" v-model="stockcash.sBeforeMoney" type="number" step="100" />
                  <label for="sBeforeMoney">剩余现金({{ stockcash.sNowMoney }})</label>
                  <Button icon="pi pi-refresh" :disabled="(sbuystyle==null)"  @click="async()=>{stockcash.sBuyStyle=useCommon.o_s(sbuystyle,'sId');await useSlade.upd_stockcash(stockcash);get_cash(useCommon.o_s(sbuystyle,'sId'));listshow()}" />
                 </span>
              </div>
            </div>   
            <div class="clear"></div>      
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="sName" v-model="newstock.sName" />
                  <label for="sName">{{ (newstock.sCode&&newstock.sCode.length>0)?"股票代码["+newstock.sCode+"]":"股票名称或者股票代码" }}</label>
                  <Button icon="pi pi-search" :disabled="(sbuystyle==null)||stockcash.sNowMoney<=0"  @click="getstockinfo" />
                </span>
              </div>
            </div>  
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="sSalePrice" v-model="newstock.sSalePrice" />
                  <label for="sSalePrice">股票价格</label>
                </span>
              </div>  
            </div>
            <div class="margin_top div_34_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="sSaleNum" v-model="newstock.sSaleNum" />
                  <label for="sSaleNum">交易数量</label>
                  <Button icon="pi pi-plus" :disabled="((newstock.sSalePrice<=0||newstock.sSalePrice==null)||(newstock.sSaleNum<=0||newstock.sSaleNum==null))" 
                   @click="async()=>{ 
                    newstock.sBuyStyle=useCommon.o_s(sbuystyle,'sId');
                    await useSlade.buy_stock(newstock);
                    listshow();
                    await callBuyMethod(sel_index)
                    }" />
                  
                </span>
              </div>
            </div>         
            <div class="clear"></div>            
          </div>

      </div>      
  </div>
</template>
    
<script setup>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import useSlade from '@/services/useSlade';
import { ref, onMounted,watchEffect } from "vue";
import useAlert from '@/services/useAlert';
import useCommon from '@/services/useCommon';
// const props = defineProps({
//     mydata:Array
// });

let stocktype=ref({})
const stocktypes=ref([])
const stocktypedatas=ref([])
const is_fill=ref(false)
const newstock=ref({})
const sbuystyle=ref(null)
const stockcash=ref({})
stockcash.value.sBeforeMoney=0
stockcash.value.sNowMoney=0



const listshow = async () => {
  stocktypes.value=await useSlade.get_stocktypes()
  stocktypedatas.value=useCommon.o_o(stocktypes.value)
  is_fill.value=(stocktypes.value.length>0)
}

const get_cash=async(sIdIn)=>{
  stockcash.value=await useSlade.get_stockcash({sBuyStyle:sIdIn+''})  
  if(stockcash.value==null){
    stockcash.value={}
    stockcash.value.sBeforeMoney=0
    stockcash.value.sNowMoney=0
  }
}

const sel_index=ref(0)
const get_index=(event)=>{
  const selectedValue = event.value
  const selectedIndex = stocktypes.value.findIndex(item => item.sId === selectedValue.sId)  
  console.log('Selected Index:', selectedIndex)
  sel_index.value=selectedIndex
  get_cash(useCommon.o_s(sbuystyle.value,'sId'))
}


onMounted(async() => {
    listshow();
})

watchEffect(() => {
  stocktypes.value
})


const getstockinfo = async () => {
  let result=await getstockprice(newstock.value.sName)  
  newstock.value.sSalePrice=result[0]
  newstock.value.sCode=result[1]
  newstock.value.sName=result[2]
  if(newstock.value.sSalePrice==0){
    useAlert.salert("未查找到股票["+newstock.value.sName+"]")
  }
}


const getstockprice = async (sname) => { 
    let infostock={}
    infostock.sName=sname
    let info= await useSlade.get_stockinfo(infostock)
    if(info&&info.dplace){
      return [info.当前价格,info.dcode,info.dname]
    }
    return [0,"",sname]
  }
// const emits = defineEmits(['onsearch']);
// function search_val(splace,scode) {
//     emits("onsearch", splace,scode)
// }
</script>

<style >
.up{
  font-weight: bold;
  color: red;
}

.down{
  font-weight: bold;
  color: green;
}

</style>
  
    