<template>  
  <div v-if="!isEdit">
    <div style="height: 1.5em;"></div>
    <div>
      <SearchBlock :sname="'资料'" @onsearch="filters" :flabel="filter_arr"></SearchBlock>
    </div>
    <div class="padding" style="min-height: 1500px;">

      <div class="blog_outline"
        v-for="{ fId,fImgUrl,fDisplayName,fLabel,fDescription,fViewTimes,fDownloadTimes,fUpTimes,fStyle1,fStyle2,fManufacture,CreateTime,fDloadName,fOtherUrl } in blogs"
        :key="fId" style="position:relative;">
        <div v-if="blogs && blogs.length > 0" class="blog">
          <div :style="'height: 8em;background-image:'+fImgUrl" class="blog_img">

            <!-- <img :src=" (bImgUrl&&bImgUrl.length>0)?bImgUrl.replace('url(','').replace(')',''):''" top alt="..." style="width: 100%;height: 100%;z-index: -99;"/> -->
          </div>
          <div style="height: 7em;padding: 0.5em;text-align: left;">
            <a :href="'/download/' + fId" @click="page_url = fId" style="font-weight: bold;color: #4d4b4b;">{{ fDisplayName }}
              <div style="height: auto;text-align:left;color: #a6a2a2;font-size:0.8em !important;overflow:hidden;">
                <div><i class="pi pi-building"></i> {{ fManufacture }} </div>
                <div><i class="pi pi-users"></i> {{ fLabel }}</div>
                <div :title="fDescription"><i class="pi pi-bookmark"></i></div>
                <div><i class="pi pi-user"></i> {{ fStyle1 }} - {{ fStyle2 }}</div>

              </div>
            </a>
            <div style="padding: 0.5em;font-size: 15px !important;">

            </div>
          </div>
          <div style="padding: 0.5em;">
            <div>
              <div style="float: left;width: 8em;font-size: 15px !important;">
                <small class="text-muted"><i class="pi pi-calendar-plus"></i> {{ CreateTime.slice(0, 10) }} </small>
              </div>

              <div style="margin-left: 8em;font-size: 15px !important;">
                <div class="div_33_100"><small class="text-muted"> <i class="fas fa-heart"></i> {{ useCommon.numtostirng(fUpTimes) }} </small></div>
                <div class="div_33_100"><small class="text-muted"> <i class="fas fa-eye"></i> {{useCommon.numtostirng( fViewTimes) }} </small></div>
                <div class="div_33_100"><small class="text-muted"> 
                    <a v-if="fDloadName" @click="useApi.downloadfetch('api/File/' + fId, fDloadName)" :title="' 下载' + fId" style="cursor: pointer;">
                      <i class="fas fa-download"></i> {{ useCommon.numtostirng(fDownloadTimes) }} 
                    </a>
                    <a :href="fOtherUrl" target="_blank" v-if="fOtherUrl">
                      <i class="fas fa-cloud"></i> {{ useCommon.numtostirng(fDownloadTimes) }} 
                    </a>
                </small></div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>

        </div>
      </div>
      <div class="clear"></div>
      <div style="height: 3em;"></div>


    </div>
  </div>
  <div v-else>
    <FileEdit @ondatachange="listshow" :blog_id="projectname" :style="'file'"></FileEdit>
  </div>
</template>
  
<script setup>
import FileEdit from '@/components/FileEdit.vue';
import useBlog from '@/services/useBlog';
import useApi from "@/services/useApi";
import { ref, onMounted, defineProps,onBeforeUnmount,defineEmits } from "vue";
import { useStore } from "vuex";
import SearchBlock from "@/components/SearchBlock.vue";
import useSlade from "@/services/useSlade";
import useFree from '@/services/useFree';
import useCommon from '@/services/useCommon';
import useSilkroadInfo from '@/services/useSilkroadInfo';
const props = defineProps({
  filter: String,
  project_id: String
})
let isEdit = ref(false)
let projectname = ref('')
const store = useStore();
let authed = ref(store.state.authed);
let isadmin = ref(store.state.superadmin);
let blogs = ref([])
let local_blogs = ref([])

const filters = (name, type) => {
  blogs.value = useApi.search_item(name, type, local_blogs.value)
}
const filter_arr = [{ name: '名称', type: 'fName' }, { name: '标签', type: 'fLabel' }]
const listshow = async () => {
  if (store.state.authed)
    blogs.value = await useSlade.get_files()
  else
    blogs.value = await useFree.get_files()
 
  local_blogs.value = blogs.value

}

const get_role = async () => {
  let role_data = await useBlog.get_role();
  isadmin.value = (role_data == 'SysAdmin')
}
const emits = defineEmits(['add-button','remove-button']);
const my_bt1 = {
          label: '增加资源',
          icon: 'pi pi-plus',
          command: () => {
            isEdit.value = !isEdit.value
          }
      }
onBeforeUnmount(() => {
  emits('remove-button', my_bt1);
});
onMounted(() => {
  if(authed.value&&isadmin.value)
  {
    emits('add-button', my_bt1);
  }
  else{
    emits('add-button', []);
  }
  listshow();
  if (authed.value)
    get_role()
  if (props.filter && props.filter.length > 0) {
    filters()
  }
  if (props.project_id) {    
    projectname.value = props.project_id
    isEdit.value = true;
  }
  else
    useSilkroadInfo.pagename(document,"[下载中心]")
  document.title = '[资源下载]-[碳硅锗兮]';
})
</script>
  