import api from '@/services/useApi';
import useCommon from '@/services/useCommon';
import common from '@/services/useCommon';

const getapi=api.postapi;
const get_sdate=common.get_sdate;

function toChartData(data,key,type,name)
{ 
    let dataset={}
    dataset.label=name
    dataset.type=type
    dataset.borderWidth= 2
    dataset.data=[]
    if(data&&data.length>0)
    {
        data.forEach(element => {            
            dataset.data.push(element[key])
        });
    }
    return dataset
}
function toChartLabel(data,key)
{    
    let labels=[] 
    if(data&&data.length>0)
    {
        data.forEach(element => {            
            labels.push(element[key])           
        });
    }
    return labels
}

function DescData(data)
{
    let newdata=[]  
    if(data&&data.length>0)
    {
        let ii=data.length-1;
        data.forEach(() => {            
            newdata.push(data[ii])
            ii-=1;           
        });
    }
    return newdata
}

function toChartLabelDate(data,key)
{    
    let labels=[]
    if(data&&data.length>0)
    {
        data.forEach(element => {            
            labels.push(get_sdate(element[key]))
        });
    }
    return labels
}
async function getMyToday(){ 
    const memdata=await getapi("slade/user",
    {
        apiname:'get_mytoday',
    })  
    
    if(memdata&&memdata.data&&memdata.data.data&&memdata.data.data.length>0)
    {
       
        const ndata  =DescData(memdata.data.data) 
        
        let pdatas=[];    
        let pdata1=toChartData(ndata,'snum','line','当天处理事务');
        let pdata2=toChartData(ndata,'scost','bar','当天耗时');
        pdatas.push(pdata1);
        pdatas.push(pdata2);
        return {
            labels: toChartLabelDate(ndata,'pLastTime'),
            datasets: pdatas
        }
    }
}

async function getMyDevote(account){
    const memdata=await getapi("slade/user",
    { 
        apiname:'get_mydevote',
        data0:account+''
    });
    if(memdata&&memdata.data&&memdata.data.data&&memdata.data.data.length>0)
    {
        
    let pdatas=[];
    let pdata1=toChartData(memdata.data.data,'pcost','pie','项目投入占比');    
    pdatas.push(pdata1);     
    return {
        
        labels: toChartLabel(useCommon.jsons_o(memdata.data.data),'pname'),
        datasets: pdatas
    }}
}

async function getoneday(date,day){  
    const memdata=await getapi("firesu/user",
    {
        apiname:'get_oneday',
        data0:date+'',
        data1:day+''
    })  
    return memdata.data
}

export default {
    getMyToday,getMyDevote,getoneday
}
    