

function add_input(baseobjects,creator,belonginfo,dad){
    
    let plandata={};
    plandata.index=baseobjects.length;
    plandata.dad=(dad&&dad>0)?(dad+''):'self';
    plandata.pname=0;
    plandata.pgid=0;
    plandata.level=0;
    plandata.parent=''+plandata.index;
    plandata.plan="";
    plandata.cost=0.1;
    plandata.planstart=new Date();
    plandata.planend=new Date();
    plandata.children=[];  
    plandata.isEdit=true;  
    plandata.isChange=false;
    plandata.id=-1;  
    plandata.status="",
    plandata.order=  plandata.index,
    plandata.iskey=false,
    plandata.files="",
    plandata.creator=creator,
    plandata.pMembersName=belonginfo?belonginfo.pMembersName:''
        
    baseobjects.push(plandata);        
}






function item_string(items,key)
{  
  let strs=''; 
  if(typeof(items)!='string')
  {    
    if(items[0])
    {      
      items.forEach(obj=>{
        strs+=obj[key]+" "
    })}
  }  
  else
  {
    strs=items;
  }  
  return strs;
}

function cvalue_item(cvalue,items,keyf)
{   
  let new_arr=[]
  items.forEach(obj=>{
    if(obj[keyf]==cvalue)
    {   
      
      new_arr.push(obj);
    } 
  })
  return new_arr;
}

function citem_value(items,keyf)
{   
  let new_str=''
  items.forEach(obj=>{     
      new_str+='{'+(obj[keyf])+'},';    
  })
  if(new_str.length>0)
    new_str=new_str.slice(0,new_str.length-1)
  return new_str;
}



function object_string(items,key)
{
  let strs=''; 
  if(typeof(items)!='string')
  {
    if(typeof(items)=='object')    
      return items[key];      
  }  
  else  
    strs=items;
  return strs;
}


function cut_string(strs,len)
{
  if(strs&&strs.lenth>len)
  {
    return strs.slice(0,len);
  }
  else
  {
    return strs
  }
}

function cal_start(tmodel,index){
    let date =new Date(tmodel[index].pStart);  
    date = date.getFullYear() + '-'
            + (date.getMonth()+1) + '-'   // 这里加1是因为getMonth()的返回值是为0~11
            + date.getDate() + ' '
            + date.getHours() + ':'
            + date.getMinutes() + ':'
            + date.getSeconds();
    date = Date.parse(new Date(date))/1000; // 转换成时间戳，返回值是ms，除1000后转化成秒
    date += (86400) * tmodel[index].pCost; // 一天是86400秒
    let newDate = new Date(parseInt(date) * 1000); // 把时间戳转换成日期
    tmodel[index].pEnd = newDate
  }
  
  
  function cal_end(tmodel,index){
    let date = new Date(tmodel[index].pEnd);
    date = date.getFullYear() + '-'
            + (date.getMonth()+1) + '-'   // 这里加1是因为getMonth()的返回值是为0~11
            + date.getDate() + ' '
            + date.getHours() + ':'
            + date.getMinutes() + ':'
            + date.getSeconds();
    date = Date.parse(new Date(date))/1000; // 转换成时间戳，返回值是ms，除1000后转化成秒
    date -= (86400) * tmodel[index].pCost; // 一天是86400秒
    let newDate = new Date(parseInt(date) * 1000); // 把时间戳转换成日期
    tmodel[index].pStart = newDate
  }

  function set_today(data,helss,tddata){
    if(!helss.is_ededit)
    {   
        helss.is_ededit=true;
        tddata.td_slct_date=data.date;
        tddata.td_cost=data.cost;
        tddata.td_descp=data.content;
        tddata.td_edit=true;
        tddata.td_id=data.id;
    }
    else
    {
        helss.is_ededit=false;
        reset_today(tddata);
    }
    
}


function reset_today(tddata){
    tddata.td_cost=0;
    tddata.td_descp="";                        
    tddata.td_id=-1;
}


export default {add_input,item_string,object_string,cut_string,cal_end,cal_start,set_today,reset_today,cvalue_item,citem_value}