
<template>
  <div>
    <div style="height: 12em;background-size:cover;background-image:url(images/f4.jpg)">

    </div>
    <!-- Scrollspy -->
    <div id="scrollspy-collapsible">
      <div class="card">
        <div>
          <div style="margin: 1em;padding: 1em;border-radius: 1em 1em;">
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.dName" />
                  <label for="companyname">流程/注意事项</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.dStyle1" />
                  <label for="companyname">经验大类</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_33_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.dStyle2" />
                  <label for="companyname">经验小类</label>

                </span>
              </div>
            </div>
            <div class="margin_top div_100">
              <div class="p-inputgroup flex-1 ">
                <span class="p-float-label">
                  <InputText id="companyname" v-model="myobject.dDescription" @mouseleave="search_blog(myobject)" />
                  <label for="companyname">经验描述</label>
                  <Button icon="pi pi-search" title="规格书名称" @click="search_blog(myobject)" />
                </span>
              </div>
            </div>
            <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue" :sharedata="getshare"></ObjectShare>
            <div class="clear"></div>
            <div class="place1em"></div>
            <div>
              <Button icon="pi pi-plus" v-if="myobject.dId <= 0" class="margin" title="上传资料" label="新增"
                @click="create_blog()" />
              <Button icon="pi pi-refresh" v-if="myobject.dId > 0" class="margin" title="更新资料" label="更新"
                @click="update_blog()" />
              <Button icon="pi pi-times" v-if="myobject.dId > 0" class="margin" title="删除资料" label="删除"
                @click="delete_blog()" />

            </div>
            <div class="place1em"></div>

            <div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 1em;"></div>
    </div>

  </div>
</template>
    
<script setup>
import ObjectShare from '@/components/ObjectShare.vue'
import { ref, defineEmits } from "vue";
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import useCommon from '@/services/useCommon';
import useSlade from "@/services/useSlade"
import useAlert from '@/services/useAlert';
let myobject = ref({});
let sendshare = ref({});
let getshare = ref({});
sendshare.value.dId = -1
const getrolevalue = (obj) => {
  sendshare.value = obj
}

const beforepost = (obj) => {
  console.log(obj)
}

const create_blog = async () => {
  if (myobject.value.dName && myobject.value.dDescription && myobject.value.dStyle1 && myobject.value.dStyle2) {
    let postgroup = useCommon.o_o(myobject.value)
    beforepost(postgroup);
    await useSlade.add_clist(postgroup, sendshare.value);
    search_blog();
    ondatachange()
  }
  else
    useAlert.salert("信息不完整")
}

const update_blog = async () => {
  if (myobject.value.dName && myobject.value.dDescription && myobject.value.dStyle1 && myobject.value.dStyle2) {
    let postgroup = useCommon.o_o(myobject.value)
    beforepost(postgroup);
    await useSlade.upd_clist(postgroup, sendshare.value);
    search_blog();
    ondatachange()
  }
  else
    useAlert.salert("信息不完整")
}

const delete_blog = async () => {
  let postgroup = useCommon.o_o(myobject.value)
  beforepost(postgroup);
  await useSlade.del_clist(postgroup, sendshare);
  search_blog();
  ondatachange()
}

const search_blog = async () => {
  if (myobject.value.dDescription&&myobject.value.dDescription.length >0) {
    let postgroup = useCommon.o_o(myobject.value)
    beforepost(postgroup);
    let getresult = await useSlade.get_clist(postgroup);
    if (getresult && getresult.dDescription) {
      getshare.value = getresult
      myobject.value = getresult
    }
    else {
      myobject.value.dId = -1;
    }

    ondatachange()
  }
}




const emits = defineEmits(['ondatachange']);
function ondatachange() {
  emits("ondatachange")
}
</script>
  
    