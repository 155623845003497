<template>
  <div class="about">
    
  </div>
</template>

<script>
  import useApi from '@/services/useApi';
  export default{

    
    mounted(){
      useApi.loginout();
      this.$router.replace({name: 'home'}) 
    }
  }

</script>


