<template>
  <div class="login_panel">
  <div class="login_left">
    <MDBCarousel
    v-model="carousel3"
    :items="items3"
    :indicators="false"
    class="login_banner"
  />
  <div class="login_right">
    <div>      
      <TabView>
          <TabPanel header="登录">
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="lusername" v-model="login.uAccount" />
                    <label for="lusername">账号</label>
                </span>             
              </div>
            </div>
            

            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="luPass1" v-model="login.uPass1" type="password" />
                    <label for="luPass1">密码</label>
                </span>             
              </div>
            </div>
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="luProvCode" v-model="login.uProvCode" /> <Button :disabled="logintime<5" style="margin: 0.1em;"  @click="generate_improve(login)" icon="pi pi-spinner"/>   
                    <label for="luProvCode">{{'输入 '+login.uNum1+' x '+login.uNum2+' = ?'}}</label>
                </span>             
              </div>
            </div>

            <div class="margin_top"> 
              <Button style="margin: 0.1em;" :disabled="logintime<5" :label="'登录'+((logintime<5)?logintime:'')" @click="logintime=4;login_wait_time();myuserlogin(login,url);" icon="pi pi-user"/>   
            </div>

          </TabPanel>
          <TabPanel header="注册">
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="uAccount" v-model="login.uAccount" />
                    <label for="uAccount">账号</label>
                </span>             
              </div>
            </div>
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="uPass1" v-model="login.uPass1" type="password" autocomplete="false"/>
                    <label for="uPass1">密码</label>
                </span>             
              </div>
            </div>
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="uPass2" v-model="login.uPass2" type="password" autocomplete="false"/>
                    <label for="uPass2">再次输入密码</label>
                </span>             
              </div>
            </div>
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="uEmail" v-model="login.uEmail" autocomplete="false"/>
                    <label for="uEmail">邮箱地址</label>
                </span>             
              </div>
            </div>
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="uEmailReply" v-model="login.uEmailReply" /> 
                    <Button style="margin: 0.1em;"  @click="getmyode(login);wait_time(isDisposed,time)" :disabled="isDisposed"  icon="pi pi-spinner" autocomplete="false"/>   
                    <label for="uEmailReply">{{'邮箱验证码('+time+')'}}</label>
                </span>             
              </div>
            </div>

            <div class="margin_top"> 
              <Button style="margin: 0.1em;" label="注册" @click="register_user(login)" icon="pi pi-user-plus"/>   
            </div>
          </TabPanel>
          <TabPanel header="密码找回">
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="suEmail" v-model="login.uEmail" />
                    <label for="suEmail">邮箱地址</label>
                </span>             
              </div>
            </div>
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="suEmailReply" v-model="login.uEmailReply" /> 
                    <Button style="margin: 0.1em;"  @click="getmyode(login)" icon="pi pi-spinner" :disabled="login.isDisposed"/>   
                    <label for="suEmailReply">{{'邮箱验证码('+time+')'}}</label>
                </span>             
              </div>
            </div>

            
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="suPass1" v-model="login.uPass1" type="password" />
                    <label for="suPass1">密码</label>
                </span>             
              </div>
            </div>
            <div class="margin_top"> 
              <div class="p-inputgroup flex-1 ">        
                <span class="p-float-label">
                    <InputText id="suPass2" v-model="login.uPass2" type="password" />
                    <label for="suPass2">再次输入密码</label>
                </span>             
              </div>
            </div>
            

            <div class="margin_top"> 
              <Button style="margin: 0.1em;" label="找回密码" icon="pi pi-user-edit"/>   
            </div>

          </TabPanel>
      </TabView>  
    </div>
  </div>
  
  <div style="clear: both ;"></div>

  </div>
</div>
 
  



  
</template>
<style>
  .margin_div{
    margin: 0.5em;
  }
  .btn_left{
    padding: 0em 0em 0em 1em;
    border-radius: 0.2em 0.2em;
    border: 1px solid rgb(193, 181, 181);
  }

</style>
<script setup>
  
  import TabView from 'primevue/tabview';
  import TabPanel from 'primevue/tabpanel';
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button'; 
  import {MDBCarousel} from "mdb-vue-ui-kit"; 
  import {  ref,onMounted } from "vue";
  import loginapi from '@/services/useApi'
  import { useRoute } from 'vue-router';

  let login=ref({});
  login.value.uAccount='';
  login.value.uEmail='';
  login.value.uEmailReply='';
  login.value.uPass1='';
  login.value.uPass2='';
  login.value.uProvCode='';
  login.value.uNum1=0;
  login.value.uNum2=0;
  login.value.uNumSum=0;

  let isDisposed=ref(false);
  
  const getmyode=loginapi.get_myode;

  document.title = '[登录和注册]-[碳硅锗兮]';
  document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
  document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
  
  


  const route = useRoute();
  //const router = useRouter();
  const url = route.query.redirect;
  const myuserlogin= loginapi.myuserlogin;
  


  const register_user=loginapi.register_user;
  onMounted(()=>{
    console.log(activeTabId2);
    generate_improve(login.value)
  })
  let time=ref(60);
  const activeTabId2 = ref('ex2-1');
  const items3 = [
    {
      src: "img/tm-bg-3.jpeg",
      alt: "..."
    },
    {
      src: "img/tm-bg-3.jpeg",
      alt: "..."
    },
    {
      src: "img/tm-bg-3.jpeg",
      alt: "..."
    }
  ];
  const carousel3 = ref(0);
  const wait_time=()=>{      
      if (time.value <= 0) {
        isDisposed.value = false;
        time.value = 60;
      } 
      else
      {
        isDisposed.value=true
        setTimeout(() => {time.value--;wait_time();}, 1000);
      }
    } 

    let logintime=ref(5);
    const login_wait_time=()=>{      
      if (logintime.value <= 0) {
        logintime.value = 5;
        generate_improve(login.value)
      } 
      else
      {
        setTimeout(() => {logintime.value--;login_wait_time();}, 1000);
      }
    } 

  function generate_improve(logininfo){
    logininfo.uNum1=Math.ceil(Math.random()*10);
    logininfo.uNum2=Math.ceil(Math.random()*10);
    logininfo.uNumSum=logininfo.uNum1*logininfo.uNum2;
      
    }

  
</script>