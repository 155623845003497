import { createStore } from 'vuex'
export default createStore({
state:{
    authed:Boolean(false),
    username:'Login',
    jwtoken :'',
    errmsg:'',
    needcheck:false,
    loading:false,
    superadmin:false,
    company:'',
    logintime:Date()
}, 

getters:{

},
mutations:{
    reloadLogin() {
        this.state.authed   =JSON.parse(localStorage.getItem( 'authed')) ;
        this.state.username =localStorage.getItem( 'username');
        this.state.jwtoken  =localStorage.getItem('jwtoken');
        this.state.superadmin  =localStorage.getItem('role')=='SysAdmin' ;
        this.state.logintime=Date(localStorage.getItem('logintime'));
    },
    errAlert(){        
        this.state.errmsg  =localStorage.getItem('errmsg');
        this.state.needcheck  =localStorage.getItem('needcheck');
    },
    
},
actions: {

},
modules: {

}
})