<template>
    <div>        
        <div style="position: relative;">
            <div class="f_glass">
                <div style="height: 2em;"></div>
                <div style="max-width: 30em;margin: auto;">
                    <div class="margin_top div_20_100" style="width: 10em;margin: auto;" v-if="props.flabel.length > 1">
                        <div class="p-inputgroup flex-1 ">
                            <span class="p-float-label">
                                <Dropdown v-model="sel_style" editable :options="props.flabel" optionLabel="name"
                                    :loading="false" />
                                <label for="dName">{{ '按照' + sel_style.name + '查找' }}</label>
                            </span>
                        </div>
                    </div>
                    <div :class="(props.flabel.length > 1) ? 'margin_top div_80_100' : 'margin_top'"
                        style="width: 19.3em;margin: auto;">
                        <div class="p-inputgroup flex-1 ">
                            <span class="p-float-label">
                                <InputText id="dName" v-model="search_tag" autocomplete="false" />
                                <label for="dName">{{ '查找' + props.sname }}</label>
                                <Button icon="pi pi-search" title="查找" @click="search_val(search_tag)" />
                                <Button icon="pi pi-bars" title="全部" @click="visible = true" v-if="props.tname" />
                            </span>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div style="height: 2em;">

                </div>
                <!-- <div style="border: 1px solid #e0e0e0;"></div> -->
                <Dialog v-model:visible="visible" :header="props.sname + '目录'" :style="{ width: '50rem' }"
                    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">                   
                    <Tree :value="nodes" class="w-full md:w-30rem">
                        <template #default="slotProps">
                            <a style="cursor: pointer;" :href="slotProps.node.data.type=='child'?'/blog/' + slotProps.node.data.url:'/blogs/'">{{
                                slotProps.node.data.name
                            }}</a>
                        </template>
                    </Tree>
                </Dialog>
                <div class="bubble" v-for="(index) in bubbles" :key="index"></div>
            </div>
        </div>
    </div>
</template>
<script setup>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
// import TreeTable from 'primevue/treetable';
// import Column from 'primevue/column';    
import { ref, defineProps, onMounted, defineEmits } from "vue";
import useApi from '@/services/useApi';
import Tree from 'primevue/tree';
import Dropdown from 'primevue/dropdown';
var search_tag = ref("");
const visible = ref(false);
const nodes = ref();
const sel_style = ref(props.flabel[0])
const props = defineProps({
    sname: String,
    tname: String,
    tkey1: String,
    tkey2: String,
    tkeyname: String,
    tkeyurl: String,
    flabel: Object
})

const bubbles = [
    { x: 100, y: 100, size: 50, color: 'Green' },
    { x: 200, y: 200, size: 70, color: 'blue' },
    { x: 300, y: 300, size: 90, color: 'green' }
];
onMounted(() => {
    get_labels();

    setInterval(() => {
        bubbles.forEach((index) => {
            const randomX = Math.floor(Math.random() * (window.innerWidth - 100));
            const randomY = Math.floor(Math.random() * (window.innerHeight - 100));
            bubbles[index] = { x: randomX, y: randomY };
        });
    }, 1);
})

function get_labels() {
    useApi.postapi('firesu/free', {
        apiname: 'get_labels',
        data0: props.tname,
        data1: props.tkey1,
        data2: props.tkey2,
        data3: props.tkeyname,
        data4: props.tkeyurl
    }).then(res => {
        nodes.value = res.data;
       
    })
}
const emits = defineEmits(['onsearch']);
function search_val(value) {
    emits("onsearch", value, sel_style.value.type)
}


</script>

<style>
.bubble {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 8px;
    border-radius: 50%;
    background-color: whitesmoke;
    opacity: 0.2;
    animation: bubble-animation 2s infinite;
}




@keyframes bubble-animation {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100px);
    }
}
</style>