<template >
  <div>
    <div style="height: 12em;background-size:cover;background-image:url(images/f4.jpg)"></div>
    <TabView @tab-change="tabchange()">
      <TabPanel header="待处理的任务">
        <div class="place1em"></div>
        <CommonPlan :isparent="-1" :userid="select_account" ref="OnGoing" pstyle="MyFairs" pheader="增加任务"></CommonPlan>
      </TabPanel>
      <TabPanel header="待处理的问题">
        <div class="place1em"></div>
        <CommonPlan :isparent="-1" :userid="select_account" ref="Question" pstyle="MyQuestion" pheader="增加问题">
        </CommonPlan>
      </TabPanel>
      <TabPanel header="最近做了什么">
        <div class="place1em"></div>
        <div class="div_50_100">
          <div style="padding: 1em;">
            最近7天的精力投入
            <FireChart clabel="事务处理进展(件数)" type="pie" :cdata="datasets2" style="min-height: 28em;"></FireChart>
          </div>

        </div>
        <div class="div_50_100">
          <div style="padding: 1em;">
            这几天的精力消耗
            <FireChart clabel="事务处理进展(件数)" :cdata="datasets" style="min-height: 28em;" v-if="store.state.authed">
            </FireChart>
          </div>
        </div>
        <div class="clear"></div>
        <div class="place1em"></div>
        回头看看最近都做了什么？
        <CommonPlan :isparent="-1" :userid="select_account" ref="Finished" pstyle="MyFinished" pheader=""></CommonPlan>
      </TabPanel>

    </TabView>
  </div>
</template>

<script setup >

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import CommonPlan from '@/components/CommonPlan.vue';
//import MyFPlans from '@/components/CommonMyBugs.vue'; 
import FireChart from '@/components/FireChart.vue';
import useCommon from '@/services/useCommon';
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import { useStore } from "vuex";
import mychart from '@/services/useChart';
import useSlade from '@/services/useSlade';



const getmyt = mychart.getMyToday;
const getmyd = mychart.getMyDevote;
const route = useRoute();
onMounted(() => {
  //import { useRoute } from 'vue-router';
    //const route = useRoute();
    useSlade.inject_route(route)
  geteachday()
})

let today_str = new Date();

var datasets = ref([])
var datasets2 = ref([])
async function geteachday() {
  if (store.state.authed) {
    datasets.value = (await getmyt(0))
    datasets2.value = (await getmyd(useCommon.format_date(today_str, 'yyyy-MM-dd')))
  }
}

document.title = '[时间管理]-[碳硅锗兮]';
document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')

const store = useStore();
var select_account = (store.state.userid);
const OnGoing = ref(null);
const Question = ref(null);
const tabchange = () => {
  OnGoing.value.getaffairs();
  Question.value.getaffairs();
}

</script>
