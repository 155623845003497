
<template id="blog_show">
   <div  style="position: relative;">
    <Dialog v-model:visible="ismenu_show" :header="'目录'" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"> 
      <CommonPlan :isparent="-1"  ref="OnGoing" pstyle="Blogs" pheader="增加笔记"></CommonPlan>
    </Dialog>
  </div>  
  <CommonBlogs :isparent="-1" ref="OnGoing" pstyle="Blogs" pheader="增加笔记" :pid="props.blog_id.replace('blog_','')"></CommonBlogs>
  
</template>
<script setup>
  import { ref,defineProps,onMounted,defineEmits,onBeforeUnmount } from "vue";
  import CommonBlogs from '@/components/CommonBlogs.vue';
  import Dialog from 'primevue/dialog';
  import CommonPlan from '@/components/CommonBlogMenu.vue';
  import "prismjs/themes/prism-tomorrow.min.css"//引入代码高亮主题（这个去node_modules的安装prismjs中找到想使用的主题即可）
  import "prismjs/plugins/line-numbers/prism-line-numbers.min.js"
  import "prismjs/plugins/line-numbers/prism-line-numbers.min.css"//行号插件的样式
  import Prism from "prismjs"//导入代码高亮插件的core（里面提供了其他官方插件及代码高亮样式主题，你只需要引入即可）
  import { useStore } from "vuex";
  import { useRoute } from 'vue-router';
  import useSlade from '@/services/useSlade';
  const props = defineProps({        
        blog_id: String
    })
  const route = useRoute();
  const emits = defineEmits(['add-button','remove-button']);
  const my_bt1 = {
          label: '菜单',
          icon: 'pi pi-bars',
          command: () => {
            ismenu_show.value = !ismenu_show.value
          }
      }
  onBeforeUnmount(() => {
    emits('remove-button', my_bt1);
    //
  });
  onMounted(() => {
    emits('add-button', my_bt1);
    useSlade.inject_route(route)
    if (props.blog_id && props.blog_id.length > 0) {
      search_blog();
      }
  })
  const store = useStore();
  let authed = ref(store.state.authed);
  let ismenu_show = ref(false)
  let blog=ref({})
  let setroledata=ref({})
  const search_blog=async ()=>{
    blog.value.bId=props.blog_id.replace('blog_','')
    let result={}
    console.log(authed.value)
    
    if(result&&result.bId)
    {
      document.title='['+result.bName+']-[笔记]-[碳硅锗兮]';
      blog.value=result
      setTimeout(function() {
        Prism.highlightAll();
                  }, 1000);
    }
    
    setroledata.value={rdRange:{rdRange: '所有人可见' },wrRange:{ wrRange: '仅自己编辑' },sShareGroup:"{\"bId\":"+blog.value.bId+",\"bName\":\""+blog.value.bName+"\"}"}
  }
   
</script>



