import api from '@/services/useApi';
import useAlert from './useAlert';

const alert = useAlert.salert
const postapi = api.postapi;

async function search_blog(bname) {
  const memdata = await postapi("firesu/user",
    {
      apiname: 'search_blog',
      data0:bname
    })
    
  if(memdata.data&&memdata.data.data[0])
    return memdata.data.data[0]
}

async function read_blog(bname) {
  const memdata = await postapi("firesu/free",
    {
      apiname: 'read_blog',
      data0:bname
    })
    
  if(memdata.data&&memdata.data.data[0])
    return memdata.data.data[0]
}

async function read_diary(bname) {
  const memdata = await postapi("firesu/user",
    {
      apiname: 'read_diary',
      data0:bname
    })
    
  if(memdata.data&&memdata.data.data[0])
    return memdata.data.data[0]
}

async function delete_blog(bid) {
  await postapi("firesu/user",
    {
      apiname: 'delete_blog',
      data0:bid+''
    }).then(res => {
      alert(res.data);
    })
}

async function create_blog(bId, bContent, bsContent, bName, bKeyWords, bDescription, bImgUrl, bRoleGroup,bRange, bText) {
  bId=bId+''
  if (bName != null && bName.length >= 5 && bId && bId.length >= 5) {
    await postapi("firesu/user", 
    {
      apiname: 'create_blog',
      data0: bId,
      data1: bContent,
      data2: bsContent,
      data3: bName,
      data4: bKeyWords,
      data5: bDescription,
      data6: bImgUrl,
      data7: bRoleGroup,
      data8: bRange,
      text: bText
    }).then(res => {
      alert(res.data);
    })
  }
  else
    alert("小组名字不得少于5个字");
}

async function update_blog(bId, bContent, bsContent, bName, bKeyWords, bDescription, bImgUrl, bRoleGroup,bRange, bText) {
  bId=bId+''
  if (bName&& bName.length >= 5 && bId && bId.length >= 5) {
    await postapi("firesu/user", 
    {
      apiname: 'update_blog',
      data0: bId,
      data1: bContent,
      data2: bsContent,
      data3: bName,
      data4: bKeyWords,
      data5: bDescription,
      data6: bImgUrl,
      data7: bRoleGroup,
      data8: bRange,
      text: bText
    }).then(res => {
      alert(res.data);
    })
  }
  else
    alert("小组名字不得少于5个字");
}

async function update_title(bId, bContent, bsContent, bName, bKeyWords, bDescription, bImgUrl, bRoleGroup,bRange, bText) {
  bId=bId+''
  if (bName != null && bName.length >= 5 && bId && bId.length >= 5) {
    await postapi("firesu/user", 
    {
      apiname: 'update_title',
      data0: bId,
      data1: bContent,
      data2: bsContent,
      data3: bName,
      data4: bKeyWords,
      data5: bDescription,
      data6: bImgUrl,
      data7: bRoleGroup,
      data8: bRange,
      text: bText
    }).then(res => {
      alert(res.data);
    })
  }
  else
    alert("小组名字不得少于5个字");
}


async function get_blogs() {
  const memdata = await postapi("firesu/free",
    {
      apiname: 'get_blogs'
    })
  if(memdata.data&&memdata.data.data)
    return memdata.data.data
}

async function get_diary() {
  const memdata = await postapi("firesu/user",
    {
      apiname: 'get_diary'
    })
  if(memdata.data&&memdata.data.data)
    return memdata.data.data
}
async function get_role() {
  const memdata = await postapi("firesu/user",
    {
      apiname: 'get_role'
    })
  if(memdata.data&&memdata.data)
    return memdata.data
}

export default {
  search_blog, create_blog,get_blogs,get_role,
  update_blog,delete_blog,update_title,read_blog,get_diary,read_diary

}
